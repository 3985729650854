import UserApi from "@api/user/user";
import CopilotResults from "@assets/icons/chat_ai_output.png";
import UserInput from "@assets/images/userInput.png";
import GoBack from "@commonComponents/go-back";
import SaveQuit from "@commonComponents/save-quit";
import { ContentToExport } from "@commonComponents/gcf-congratulation/components/ContentToExport";
import { ModalHeader } from "@commonComponents/gcf-congratulation/components/ModalHeader";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { Col, Divider, Modal, Spin } from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
// @ts-ignore
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CharacterImg from "@assets/images/Congrats_character.png";
import closeIcon from "@assets/images/cross.svg";
import style from "./style.module.scss";
import MarkdownViewer from "@commonComponents/markdown-viewer";

const GCFCongratulation = () => {
  const pdfRef = useRef(null);
  const { type } = useParams();
  const { pathname } = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popUpData, setPopUpData] = useState([]);
  const [modelUsed, setModelUsed] = useState(null);
  const [sendEmailState, setSendEmailState] = useState<
    "successful" | "error" | null
  >(null);

  const projectName = localStorage.getItem("projectName");
  const userData = localStorage.getItem("userData");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const {
    user: {
      setConceptNoteLoading,
      loadGetCongratulationResults,
      loadingGetCongratulationResults,
      loadGetDraftResults,
      loadGetAdvisorResults,
      loadGetTheoryOfChangeResults,
      loadGetNoObjectionProcedureResults,
      getProjectNameData,
      loadRationalAdvisorResults,
      getProjectReportPdf,
    },
    modelType: { getSelectedModelType },
  } = useStore(null);

  const email: string = localStorage.getItem("email");

  const afterCongratulationPopupData = async () => {
    const projectName = getProjectNameData
      ? JSON.parse(getProjectNameData).project_name
      : localStorage.getItem("projectName");
    const data = { project_name: projectName };
    let result =
      type === "draft"
        ? await loadGetDraftResults(data)
        : type === "advisor"
          ? await loadGetAdvisorResults(data)
          : type === "no-objection-procedure"
            ? await loadGetNoObjectionProcedureResults(data)
            : type === "theory-of-change"
              ? await loadGetTheoryOfChangeResults(data)
              : type === "rational-advisor"
                ? await loadRationalAdvisorResults({ ...data, email })
                : await loadGetCongratulationResults(data);

    const modelInfo = result.model_used;
    setModelUsed(modelInfo);

    delete result.model_used;
    const popupData = { ...result };
    setPopUpData(popupData);
  };

  useEffect(() => {
    afterCongratulationPopupData();
  }, []);

  const sections = Object.keys(popUpData);

  const sectionDataHandler = (item) => {
    if (Object.keys(popUpData[item]).length === 0) {
      return (
        <>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <img
              style={{ width: "30px", height: "30px" }}
              src={UserInput}
              alt="User Input"
            />
            <p
              style={{
                fontStyle: "italic",
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.6)",
                margin: "5px 0",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                flexShrink: 1,
                minWidth: 0,
              }}
            >
              User Input...
            </p>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <img
              style={{ width: "30px", height: "30px" }}
              src={CopilotResults}
              alt="Copilot Results"
            />
            <p
              style={{
                margin: "5px 0",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                flexShrink: 1,
                minWidth: 0,
              }}
            >
              Copilot Results...
            </p>
          </div>
        </>
      );
    }

    return (
      <>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <img
            style={{ width: "30px", height: "30px" }}
            src={UserInput}
            alt="User Input"
          />
          <p
            style={{
              fontStyle: "italic",
              fontSize: "14px",
              color: "rgba(0, 0, 0, 0.6)",
              margin: 0,
              overflowWrap: "break-word",
              whiteSpace: "normal",
              flexShrink: 1,
              minWidth: 0,
            }}
          >
            {Array.isArray(popUpData[item])
              ? "User Input..."
              : (popUpData[item]?.narratives?.[0] ??
                Object.entries(popUpData[item])
                  .map((x) => {
                    return x[0] !== "evaluation" ? `${x[1][0]}` : "";
                  })
                  .filter(Boolean)
                  .join("\n"))}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            margin: "10px 0px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <img
            style={{ width: "30px", height: "30px" }}
            src={CopilotResults}
            alt="Copilot Results"
          />
          <div
            style={{
              margin: 0,
              padding: "0 40px 0 0",
              textAlign: "justify",
            }}
          >
            {Array.isArray(popUpData[item]) ? (
              popUpData[item]
            ) : popUpData[item]["evaluation"]?.[0] ? (
              <MarkdownViewer
                text={popUpData[item]["evaluation"][0]}
                type="copilotReport"
              />
            ) : (
              "Copilot Results..."
            )}
          </div>
        </div>
      </>
    );
  };

  const onExportPdf = async () => {
    const res = await getProjectReportPdf({
      project_name: projectName,
      email,
      functionality: pathname.includes("/no-objection-procedure/")
        ? "no objection procedure"
        : pathname.includes("/theory-of-change/")
          ? "theory of change"
          : pathname.includes("/evaluate")
            ? "concept note evaluator"
            : pathname.includes("/draft")
              ? "concept note"
              : pathname.includes("/rational-advisor")
                ? "climate rationale"
                : "project structuring",
    });

    const url = URL.createObjectURL(res);

    // Create a link element
    const link = document.createElement("a");

    // Set the href and download attributes of the link
    link.href = url;
    link.download = "report.pdf"; // Set your desired file name here

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to download the file
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  const handleSendResultToEmail = async (email: string | undefined) => {
    const user = { ...(JSON.parse(userData) || {}) };
    delete user.token;

    if (email) {
      user.email = email;
    }

    const data = {
      ...user,
      project_name: projectName || "",
      // link: `https://cfcstaging.vercel.app/project-result?project-name=${codedUrl(
      //   projectName,
      // ).queryString.slice(4)}&project-type=${type}`,
      sections: popUpData,
      functionality: pathname.includes("/no-objection-procedure")
        ? "no objection procedure"
        : pathname.includes("/theory-of-change")
          ? "theory of change"
          : pathname.includes("/evaluate")
            ? "concept note evaluator"
            : pathname.includes("/draft")
              ? "concept note"
              : pathname.includes("/rational-advisor")
                ? "climate rationale"
                : "project structuring",
    };

    await UserApi.sendResultToEmail(data)
      .then(() => {
        setSendEmailState("successful");
        setTimeout(() => {
          setSendEmailState(null);
        }, 2000);
      })
      .catch(() => {
        setSendEmailState("error");
        setTimeout(() => {
          setSendEmailState(null);
        }, 2000);
      });
  };

  const printableRef = useRef(null);

  const handlePrint = () => {
    const printFrame = document.createElement("iframe");
    printFrame.style.position = "absolute";
    printFrame.style.width = "0";
    printFrame.style.height = "0";
    printFrame.style.border = "none";

    document.body.appendChild(printFrame);

    const printDocument = printFrame.contentWindow.document;
    printDocument.open();
    printDocument.write(printableRef.current.innerHTML);
    printDocument.close();

    printFrame.contentWindow.focus();
    printFrame.contentWindow.print();

    // Optional: Remove the iframe after printing
    setTimeout(() => {
      document.body.removeChild(printFrame);
    }, 1000);
  };

  return (
    <div>
      <div
        className={`${style.gcfCongratulationContainer} ${
          type === "evaluate" ? style.evaluateBox : ""
        }`}
        style={{ height: type === "/rational-advisor" ? "38vh" : "" }}
      >
        <Col xs={22} md={16} sm={20} style={{ marginBottom: 71, flex: "none" }}>
          <div className={style.congratulationBox} style={{}}>
            <img
              src={CharacterImg}
              alt="Character"
              style={{
                marginBottom: "30px",
                width: "50%",
                aspectRatio: "3000 / 2284",
              }}
            />
            <h1>Congratulations!</h1>
            {type === "draft" && (
              <>
                <div className={style.firstParaContainer}>
                  <p className={style.pOne}>
                    You have successfully completed the Concept Note
                    questionnaire.
                  </p>
                </div>
                <div className={style.exportPdfContainer}>
                  <p>
                    <span className={style.clickableText} onClick={showModal}>
                      Click here{" "}
                      {/*{loading ? 'Loading...' : 'Click here'}{" "}*/}
                    </span>
                    to review all output generated for this concept note. Or,
                    you can return to{" "}
                    <span
                      className={style.clickableText}
                      onClick={() => navigate(constRoute?.existingProject)}
                    >
                      my projects
                    </span>
                    .
                  </p>
                </div>
              </>
            )}

            {type === "advisor" && (
              <>
                <div className={style.firstParaContainer}>
                  <p className={style.pOne}>
                    You have successfully completed the Renewable Energy Project
                    Structuring questionnaire.
                  </p>
                </div>
                <div className={style.exportPdfContainer}>
                  <p>
                    <span className={style.clickableText} onClick={showModal}>
                      Click here{" "}
                    </span>
                    to see the entire report or, you can return to{" "}
                    <span
                      className={style.clickableText}
                      onClick={() => navigate(constRoute?.existingProject)}
                    >
                      my projects
                    </span>
                  </p>
                </div>
              </>
            )}

            {type === "rational-advisor" && (
              <>
                <div className={style.firstParaContainer}>
                  <p className={style.pOne}>
                    You have completed all the components of the Climate
                    Rationale workflow.
                  </p>
                </div>
                <div className={style.exportPdfContainer}>
                  <p>
                    <span className={style.clickableText} onClick={showModal}>
                      Click here{" "}
                    </span>
                    to see the entire report or, you can return to{" "}
                    <span
                      className={style.clickableText}
                      onClick={() => navigate(constRoute?.existingProject)}
                    >
                      my projects
                    </span>
                  </p>
                </div>
              </>
            )}

            {type === "evaluate" && (
              <>
                <div className={style.firstParaContainer}>
                  <p className={style.pOne}>
                    Your Concept Note narratives have been evaluated. Please
                    review the report for detailed feedback and make any
                    necessary adjustments. If you incorporate significant
                    changes, you may resubmit your Concept Note for further
                    evaluation. We wish you the best of luck with your
                    submission to the Green Climate Fund!
                  </p>
                </div>
                <div className={style.exportPdfContainer}>
                  <p>
                    <span className={style.clickableText} onClick={showModal}>
                      Click here{" "}
                    </span>
                    to see the entire report or you can return to{" "}
                    <span
                      className={style.clickableText}
                      onClick={() => navigate(constRoute?.existingProject)}
                    >
                      my projects
                    </span>
                  </p>
                </div>
              </>
            )}
            {type === "theory-of-change" && (
              <>
                <div className={style.firstParaContainer}>
                  <p className={style.pOne}>
                    You have completed all the components of the GCF Theory of
                    Change (ToC) narrative.
                  </p>
                </div>
                <div className={style.exportPdfContainer}>
                  <p>
                    <span className={style.clickableText} onClick={showModal}>
                      Click here{" "}
                    </span>
                    to see the final report.
                    <br />
                    <div>
                      <p>
                        Use the information in this report to inform your Toc
                        for your Project Proposal. Best of luck!
                      </p>
                    </div>
                  </p>
                </div>
              </>
            )}
            {type === "no-objection-procedure" && (
              <>
                <div className={style.firstParaContainer}>
                  <p className={style.pOne}>
                    You have successfully completed the No Objection Procedure
                    flow.
                  </p>
                </div>
                <div className={style.exportPdfContainer}>
                  <p>
                    <span className={style.clickableText} onClick={showModal}>
                      Click here{" "}
                    </span>
                    to see the entire report, or you can return to{" "}
                    <span
                      className={style.clickableText}
                      onClick={() => navigate(constRoute.existingProject)}
                    >
                      my projects
                    </span>
                    .
                  </p>
                </div>
              </>
            )}
          </div>
          {/* <button
            className={style.goBtn}
            onClick={() => {
              setConceptNoteLoading(false)
              navigate(constRoute?.sustainabilityReplicabilityResults100)}
            }
          >

            <img src={LeftArrow} alt="left-arrow" /> Go Back
          </button> */}
        </Col>
      </div>
      <div className={style.btnDiv}>
        <div className={style.twoBtnDiv}>
          <div className={style.goBackBtnDiv}>
            <GoBack
              className={style.goBtn}
              onClick={
                type === "evaluate"
                  ? constRoute?.gradeProject + "/results/14"
                  : type === "advisor"
                    ? constRoute.advisorProject + "/results/5"
                    : type === "theory-of-change"
                      ? constRoute.theoryOfChangeProject + "/results/5"
                      : type === "no-objection-procedure"
                        ? constRoute.noObjectionProcedureProject + "/results/7"
                        : type === "rational-advisor"
                          ? constRoute.rationalAdvisorProject + "/results/5"
                          : constRoute?.draftProject + "/results/14"
              }
            />
            <SaveQuit className={style.goBtn} />
          </div>
        </div>
      </div>
      <Modal
        title={
          <ModalHeader
            topLine={true}
            onExportToPdf={onExportPdf}
            handlePrint={handlePrint}
            // onExportToWord={() => handleExportToDoc()}
            sendEmailState={sendEmailState}
            handleSendResultToEmail={handleSendResultToEmail}
          />
        }
        open={isModalOpen}
        onCancel={handleCancel}
        width={1200}
        className={style.modalBody}
        footer={null}
        closeIcon={<img src={closeIcon} alt={"close"} />}
      >
        <Divider />
        <div className={style.summaryInfo}>
          <p>
            Project Name:{" "}
            <strong>
              {getProjectNameData
                ? JSON.parse(getProjectNameData).project_name
                : localStorage.getItem("projectName")}
            </strong>
          </p>
          <p>
            Model Used:{" "}
            <strong>{modelUsed === null ? "N/A" : modelUsed}</strong>
          </p>
        </div>
        <div id="exportContent" ref={pdfRef}>
          <div className={style.modelText}>
            {loadingGetCongratulationResults ? (
              <div
                style={{
                  display: "flex",
                  height: 300,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin></Spin>
              </div>
            ) : (
              <div ref={printableRef}>
                <ContentToExport
                  sections={sections}
                  sectionDataHandler={sectionDataHandler}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GCFCongratulation;

import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";

import {
  useNarrativesContext,
  useTokensContext,
} from "@components/layout/main-layout/private-layout";
import RationalForm from "@components/pages/project/rational-advisor-page/rational-form";
import CommonFooterButton from "@components/common-components/footer-button";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { useForm } from "antd/es/form/Form";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { DataType } from "types/data-type";
import { baseSocketUrl } from "@api/const";
import { notification } from "@utils/notifications";

const formQuestions = [
  {
    q: <span>Please Fill In the Blanks Below:</span>,
    result: <span>Find below Co-pilot's Output:</span>,
    limit: 1000,
    placeholder: "",
  },
  {
    id: "q3",
    q: (
      <>3. If The Project Is Cross-Cutting, Enter Its Climate Context Below: </>
    ),
    limit: 1000,
    result: <>Find below Co-pilot's Output:</>,
    placeholder:
      "Guidance​ Below:\n\n" +
      "The baseline scenario description should provide a detailed and concise overview of the current conditions in the project areas, including supporting data and statistics. This should include:\n\n" +
      "Current Conditions: Describe the current state of the project areas, including climate-related hazards, infrastructure, and socio-economic conditions.\n\n" +
      "Criteria for Selecting Project Areas: Outline the criteria used to select the project areas, including stakeholder engagement processes.\n\n" +
      "Quantification of Climate Hazards: Quantify how climate hazards will affect the project's geographical area in terms of loss of lives, value of physical assets, livelihoods, and environmental or social/cultural assets. Demonstrate that climate change is the primary driver of adverse impacts and link climate change to these impacts using literature and data.\n\n" +
      "Projected Climate Change Impacts: Outline expected climate impacts by a specific year (e.g., 2030, 2040) in the subject intervention area/country/region, citing relevant sources from the database.\n\n" +
      "Climate Vulnerability Reduction: Explicitly state the project's intent to reduce climate vulnerability and improve resilience, highlighting the measures to be taken to mitigate climate-related risks and impacts.",
  },
  {
    id: "q4",
    q: (
      <>
        4. Enter The Proposed Project’s Details Below.
        <span style={{ color: "#de3b3b" }}>*</span>
      </>
    ),
    result: <>Find below Co-pilot's Output:</>,
    limit: 1000,
    placeholder:
      "Guidance Below:\n\n" +
      "1. Explain how your project activities will reduce climate change risks and impacts:\n" +
      " • Detail how your project will lessen the exposure and vulnerability of people, systems, or ecosystems to climate change.\n" +
      " • Justify your chosen activities over alternative approaches, where relevant.\n\n" +
      "2. Discuss potential barriers to your project's success:\n" +
      " • Identify potential barriers (technical, social, institutional, regulatory, etc.).\n" +
      " • Describe how your project will overcome these barriers.\n\n" +
      "3. Explain how your project will reduce exposure and/or vulnerability (Adaptation Projects Only):\n" +
      " • Focus on the direct impact of your project on vulnerable populations.\n" +
      " • Explain the measures taken to reduce their exposure to climate-related hazards.",
  },
  // {
  //   q: (
  //     <>
  //       5. Discuss all the potential barriers.
  //       <span style={{ color: "#de3b3b" }}>*</span>
  //     </>
  //   ),
  //   result: <>Find below Co-pilot's Output:</>,
  //   limit: 1000,
  //   placeholder:
  //     "Guidance below:\n\n" +
  //     "Identification of Barriers: Outline specific strategies or interventions planned to overcome each identified barrier. \n\n" +
  //     "Discuss the involvement of local communities, partnerships with governmental and non-governmental organizations, and the use of innovative technologies as part of the strategy."
  // },
  {
    id: "q6",
    q: (
      <>
        5. Discuss How the Proposed Intervention Aligns With National Plans And
        Climate Strategies. <span style={{ color: "#de3b3b" }}>*</span>
      </>
    ),
    result: <>Find below Co-pilot's Output:</>,
    placeholder:
      "​Guidance Below:\n\n" +
      "Alignment with National Priorities:\n\n" +
      "When discussing the project's alignment with national priorities, make affirmative statements that the project's activities are consistent with the host country's priorities, particularly regarding its Nationally Determined Contribution (NDC) and other climate strategies aimed at limiting temperature rise to 1.5°C. Emphasize the project's consistency with the host country's NDCs, National Adaptation Programmes of Action (NAPAs), National Adaptation Plans (NAPs), or other climate strategies. Reference specific documents by name, if available, and cite specific policies.\n\n" +
      "Stakeholder Engagement​:\n\n" +
      "When detailing engagement plans with national and local stakeholders, include governmental bodies, the Accredited Entity, civil society, academia, and other relevant parties. The narrative should demonstrate how the proposed activity aligns with the host country's climate strategies and plans, such as NAPs, NAPAs, and others. It should also show how the project fosters stakeholder ownership and aligns with national policies, strategies, and frameworks.",
    limit: 1000,
  },
  {
    id: "q7",
    q: <>6. Enter Project Monitoring and Evaluation Structure Below:</>,
    result: <>Find below Co-pilot's Output:</>,
    placeholder:
      "Guidance below:\n\n" +
      "Describe how your project will track progress and measure success in addressing climate change.\n" +
      "Include details on:\n" +
      " • Indicators:  Specific, measurable indicators to track progress towards goals (e.g., tons of CO2 emissions reduced, \n" +
      "number of hectares of restored ecosystems).\n\n" +
      " • Data Collection Methods: How data will be collected to track indicators (e.g., surveys, field measurements, remote sensing).\n\n" +
      " • Data Analysis: How data will be analyzed to assess project impact (e.g., statistical analysis, modeling).\n\n" +
      " • Reporting: How monitoring and evaluation findings will be reported to the GCF and stakeholders (e.g., annual reports, online dashboards).\n\n" +
      " • Learning and Adaptation: How the monitoring and evaluation system will be used to learn from experience and adapt project implementation.",
    limit: 1000,
  },
];

export const sectionsMap = [
  {
    text: "Identification",
    section: "i_24",
  },
  {
    text: "1.1 Identification (Cross-cutting)",
    section: "pe_48",
  },
  {
    text: "Response",
    section: "r_64",
  },
  // {
  //   text: "2.1 Potential Barriers",
  //   section: 'pb_76'
  // },
  {
    text: "National Alignment",
    section: "a_84",
  },
  {
    text: "Monitoring and Evaluation",
    section: "me_100",
  },
];

const sections = sectionsMap.map((section) => section.section);

const sectionConfig = {
  i_24: {
    upload: true,
  },
  r_64: {
    upload: true,
  },
};

const RationalAdvisorPage: FC = () => {
  const nav = useNavigate();
  const socketRef = useRef(null);
  const { type, step } = useParams();
  const { setTokens } = useTokensContext();
  const { rationalAdvisor, setRationalAdvisor } = useNarrativesContext();
  const [form] = useForm();
  const [firstStepForm] = useForm();
  const {
    user: { getSingleProjectData, projectNameData },
    modelType: { getSelectedModelType },
  } = useStore(null);

  const projectName =
    (projectNameData && JSON.parse(projectNameData).project_name) ||
    localStorage.getItem("projectName");

  const [initialInput, setInitialInput] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [sectionOutputPresent, setSectionOutputPresent] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [base64Images, setBase64Images] = useState<string[]>([]);

  const [resultsData, setResultsData] = useState("");
  const [isAnswer, setIsAnswer] = useState(false);
  const [firstStepInitialAnswers, setFirstStepInitialAnswers] = useState({
    q1a: "",
    q1b: "",
    q2: "",
    q3: "",
  });

  const results = isSubmitting
    ? ""
    : rationalAdvisor.find((el) => el.section === sections[+step - 1])?.res;

  const section = sections[+step - 1];
  const email = localStorage.getItem("email");
  const isFormPage = type === "form";
  const handleSubmit = useCallback(
    async (saveOnly = false) => {
      if (isFormPage) {
        const isFirstStep = +step === 1;

        const values = isFirstStep
          ? firstStepForm.getFieldsValue()
          : form.getFieldsValue();

        setResultsData("");

        if (saveOnly === true) {
          setIsSaving(true);
        } else {
          setIsSubmitting(true);
        }

        const data: DataType = isFirstStep
          ? {
              section: sections[+step - 1],
              questions: values,
              project_name: projectName,
              email: email,
              model: getSelectedModelType ?? "Titan",
            }
          : {
              section: sections[+step - 1],
              questions: values,
              project_name: projectName,
              email: email,
              model: getSelectedModelType ?? "Titan",
            };

        if (saveOnly === true) {
          data.save_only = true;
        }

        console.log("payload body ------------->", data);

        if (base64Images) {
          data.input = {
            text: values?.q,
            images: base64Images,
          };
        }

        const socket = io(baseSocketUrl, {
          transports: ["websocket", "polling"],
        });

        socketRef.current = socket;
        socketRef?.current?.emit("climateRationale", data);

        let timeoutId;
        if (saveOnly === true) {
          timeoutId = setTimeout(() => {
            socket.disconnect();
            setIsSaving(false);
            notification.error("An error occurred: Save request timed out.");
          }, 10000); // 10 seconds timeout
        }

        socketRef?.current?.on("answer", (answer) => {
          const isStopGeneration = localStorage.getItem("isStopGeneration");

          if (
            saveOnly === true &&
            answer === "User input is saved successfully"
          ) {
            clearTimeout(timeoutId); // Cancel timeout if response received
            socket.disconnect();
            setIsSaving(false);
            localStorage.removeItem("isStopGeneration");
            notification.success("Project saved successfully!");
            return nav(constRoute?.existingProject);
          }

          if (!isSubmitting && answer) {
            setIsSubmitting(true);
          }

          if (answer) {
            setIsAnswer(true);
          }

          if (isStopGeneration || answer === "#####") {
            socket.disconnect();
            setIsSubmitting(false);
            setIsAnswer(false);
            localStorage.removeItem("isStopGeneration");
            return;
          }

          form.setFieldValue(
            `q${+step + 1}`,
            (form?.getFieldValue(`q${+step + 1}`) ?? "") + answer,
          );
        });

        socket.on("disconnect", () => {
          clearTimeout(timeoutId); // Prevent timeout from triggering after disconnect
          localStorage.removeItem("isStopGeneration");
          setIsSubmitting(false);
          setIsAnswer(false);
        });

        if (saveOnly !== true) {
          nav(constRoute?.rationalAdvisorProject + "/results/" + step);
        }
      } else {
        if (saveOnly === true) {
          setIsSaving(true);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          setIsSaving(false);
          notification.success("Project saved successfully!");
          return nav(constRoute?.existingProject);
        }

        if (formQuestions.length < +step + 1) {
          nav(constRoute?.gcfCongratulation + "/rational-advisor");
          return;
        }
        setIsSubmitting(false);
        setIsAnswer(false);
        nav(constRoute?.rationalAdvisorProject + "/form/" + (+step + 1));
      }
    },
    [type, step, getSelectedModelType, base64Images],
  );

  const handleGoBack = () => {
    socketRef?.current?.disconnect();
    setIsSubmitting(false);
    setIsAnswer(false);
    // if (isSubmitting) return;
    if (+step === 1 && type == "results") {
      nav(constRoute?.rationalAdvisorProject + "/form/1");

      return;
    }

    if (+step < 2) {
      nav(constRoute.projectName + "/rational-advisor");
      return;
    }

    if (type === "results") {
      nav(constRoute?.rationalAdvisorProject + "/form/" + +step);
    } else {
      nav(constRoute?.rationalAdvisorProject + "/results/" + (+step - 1));
    }
  };

  const handleSaveQuit = () => {
    handleSubmit(true);
  };

  const handleViewReport = () => {
    nav(constRoute?.rationalAdvisorProject + "/results/" + +step);
  };

  const getProjectData = async () => {
    const data = {
      section: sections[+step - 1].toLowerCase(),
      project_name: projectName,
      functionality: "climate rationale",
    };

    setIsLoading(true);
    setSectionOutputPresent(false);
    setSelectedFiles(null);

    localStorage.setItem("data-for-tokens", JSON.stringify(data));

    await getSingleProjectData(data)
      .then((state) => {
        if (+step === 1) {
          setFirstStepInitialAnswers(state?.questions);
        } else {
          setInitialInput(state?.questions);
        }

        // TODO: a bug will bring you here
        if (state?.questions?.images) {
          setBase64Images(state?.questions?.images);
        }

        state && setTokens(state["tokens_remaining/tokens_purchased"]);
        if (+step === 1) {
          if (!isSubmitting) {
            form.setFieldValue(
              [`q${+step + 1}`],
              state?.generated_narratives?.find(Boolean),
            );
          }
        } else {
          if (isFormPage) {
            form.setFieldValue(
              [formQuestions[+step - 1].id],
              state?.questions[formQuestions[+step - 1].id],
            );
          } else {
            if (!isSubmitting) {
              form.setFieldValue(
                [`q${+step + 1}`],
                state?.generated_narratives?.find(Boolean),
              );
            }
          }
        }
        setRationalAdvisor((prev) => {
          return prev.map((el) => {
            if (el.section === sections[+step - 1]) {
              const obj = {
                ...el,
                res:
                  state?.generated_narratives?.map((el) => el).join("") || "",
              };

              if (obj.res !== "") {
                setSectionOutputPresent(true);
              }

              return obj;
            } else {
              return el;
            }
          });
        });
      })
      .finally(() => setIsLoading(false));

    if (+step < 1 || formQuestions.length + 1 <= +step) {
      nav(constRoute?.gcfCongratulation + "/rational-advisor");
    }
  };

  const handleStopGenerate = () => {
    if (socketRef) {
      socketRef.current.disconnect();
    }
    setIsSubmitting(false);
    setIsAnswer(false);
  };

  useEffect(() => {
    setSelectedFiles(null);
    localStorage.removeItem("isStopGeneration");
    getProjectData();
  }, [step, type]);

  useEffect(() => {
    form.resetFields();
    firstStepForm.resetFields();
    setIsValid(true);
  }, [type === "results"]);

  useEffect(() => {
    socketRef?.current?.disconnect();
    form.resetFields();
    setIsSubmitting(false);
    setIsAnswer(false);
    setResultsData(
      rationalAdvisor.find((el) => el.section === sections[+step - 1])?.res ||
        "",
    );
  }, [step]);

  useEffect(() => {
    const preventClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const elements = document.getElementsByClassName("navigation-element-js");

    for (let i = 0; i < elements.length; i++) {
      if (isLoading) {
        elements[i].addEventListener("click", preventClick);
        if (i !== +step - 1) {
          // @ts-ignore
          elements[i].style.cursor = "not-allowed";
        }
      } else {
        elements[i].removeEventListener("click", preventClick);
        // @ts-ignore
        elements[i].style.cursor = "";
      }
    }
    return () => {
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener("click", preventClick);
      }
    };
  }, [isLoading]);

  useEffect(() => {
    return () => {
      socketRef?.current?.disconnect();
    };
  }, []);

  return (
    <div style={{ padding: "30px 52px 122px" }}>
      <RationalForm
        form={form}
        firstStepForm={firstStepForm}
        value={results}
        isSubmitting={isSubmitting}
        initialState={initialInput}
        handleSubmit={handleSubmit}
        allowUpload={sectionConfig[section] && sectionConfig[section].upload}
        isFormPage={isFormPage}
        Text={
          isFormPage
            ? formQuestions[+step - 1]?.q
            : formQuestions[+step - 1]?.result || results[`result${step}`] || ""
        }
        placeholder={formQuestions[+step - 1]?.placeholder}
        maxLength={formQuestions[+step - 1]?.limit}
        disabled={type === "results" || isLoading}
        section={sections[+step - 1]}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        setIsValid={setIsValid}
        isValid={isValid}
        firstStepInitialAnswers={firstStepInitialAnswers}
        base64Images={base64Images}
        setBase64Images={setBase64Images}
        projectName={projectName}
        handleStopGenerate={handleStopGenerate}
        handleViewReport={handleViewReport}
        sectionOutputPresent={sectionOutputPresent}
        isLoading={isLoading}
        isAnswer={isAnswer}
      />
      <CommonFooterButton
        customStyle={{ left: 0 }}
        isSaving={isSaving}
        isLoadingSubmit={false}
        handleSubmit={handleSubmit}
        handlegoback={handleGoBack}
        isSubmitting={isSubmitting}
        handleSaveAndQuit={handleSaveQuit}
        isSubmit={type === "results"}
        // handleQuickNext={constRoute?.contextAndBaselineResults}
        form={+step === 1 ? firstStepForm : form}
        setIsSubmitting={setIsSubmitting}
        disableSubmit={!isValid || isLoading}
      />
    </div>
  );
};

export default memo(observer(RationalAdvisorPage));

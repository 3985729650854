import React from "react";
import styles from "./info-banner.module.scss";
import InfoIcon from "@assets/icons/InfoIcon";

const InfoBanner = ({ open, setIsOpen }) => {
  return open ? (
    <div className={styles.banner}>
      {/*<div className={styles.bannerHeader}>*/}
      {/*  <p>*/}
      {/*    Scheduled Maintenance In Progress: 04 Oct, 2024 12:00PM - 5:00PM EST*/}
      {/*  </p>*/}
      {/*</div>*/}
      <InfoIcon />
      <p>
        Climate Finance Copilot will be undergoing upgrades and you might
        experience latency and technical constraints from{" "}
        <b>
          Thursday, April 3, 2025 at 5:00 PM EST (UTC/GMT -​ 4 hours) to Sunday,
          April 6, 2025 at 5:00 PM EST
        </b>
        .​ We appreciate your patience as we implement new capabilities to
        enhance your work.
      </p>
    </div>
  ) : (
    <></>
  );
};

export default InfoBanner;

import { observer } from "mobx-react";
import React, { memo, useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import SaveAndQuit from "@assets/images/Save-Quit.svg";
import { notification } from "@utils/notifications";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { Modal } from "antd";
import { ModalSave } from "@commonComponents/save-quit/ModalSave/ModalSave";

interface Props {
  onClick?: any;
  onClickForFooter?: any;
  isSaving?: boolean;
  className?: any;
}
const SaveQuit: React.FC<Props> = observer(
  ({ onClick, isSaving, ...props }) => {
    const navigate = useNavigate();
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const prevIsSavingRef = useRef<boolean>(isSaving);

    const saveQuitHandler = () => {
      if (props?.onClickForFooter) {
        props?.onClickForFooter();
      } else {
        notification.success("Project saved successfully!");
        navigate(constRoute.existingProject);
      }
    };

    // **Step 2: Detect when isSaving changes from true -> false**
    useEffect(() => {
      if (prevIsSavingRef.current === true && isSaving === false) {
        setIsOpenModal(false); // Close the modal
      }
      prevIsSavingRef.current = isSaving; // Update previous state
    }, [isSaving]);

    const handleOpenModal = () => {
      setIsOpenModal(true);
    };
    return (
      <div className={style.mainWrraper}>
        <img
          onClick={handleOpenModal}
          src={SaveAndQuit}
          alt="ws"
          className={props?.className ? props?.className : style.saveAndQuitImg}
        />
        <ModalSave
          isOpen={isOpenModal}
          isSaving={isSaving}
          handleClose={() => setIsOpenModal(false)}
          handleSubmit={() => saveQuitHandler()}
        />
      </div>
    );
  },
);

export default memo(SaveQuit);

import GoBack from "@commonComponents/go-back";
import style from "@components/pages/project/project-name/style.module.scss";
import { constRoute } from "@utils/route";
import { useNavigate } from "react-router-dom";
import styles from "./theory-of-change-welcome.module.scss";

const TheoryOfChangeWelcome = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.welcomeModal}>
        <div className={styles.welcomeModalContent}>
          <h1 className={styles.welcomeModalTitle}>
            Welcome to the Climate Finance Copilot Theory of Change (ToC)
            Advisor!
          </h1>
          <p className={styles.welcomeModalDesc}>
            <strong>Tool Purpose:</strong> To empower you to craft the most
            compelling Theory of Change possible. It will strengthen your ToC;
            evaluate project outcomes and activities for alignment; and ensure
            that you have considered all potential risks and barriers. It will
            even suggest new activities if it identifies barriers and risks that
            were previously overlooked.
          </p>
          <p className={styles.welcomeModalDesc}>
            <strong>Input:</strong> Before initiating this workflow, ensure that
            you have gathered the following Concept Note components: - Goal
            Statement - Project Outcomes, Outputs and Activities - Barriers and
            Risks and your Monitoring and Evaluation narrative.
          </p>
          <p className={styles.welcomeModalDesc}>
            Should you have any questions or concerns, please feel free to
            contact us at any time.
          </p>
          <p className={styles.welcomeModalGreetings}>The Janus Team</p>
        </div>
        <div
          className={styles.welcomeModalButton}
          onClick={() => navigate(constRoute.projectName + "/theory-of-change")}
        >
          Continue
        </div>
      </div>

      <div className={style.btnDiv}>
        <div className={style.twoBtnDiv}>
          <div className={style.goBackBtnDiv}>
            <GoBack
              onClick={constRoute?.selectFunction}
              className={style.goBtn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TheoryOfChangeWelcome;

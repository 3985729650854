import { Input } from "antd";
import { Label } from "../label";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { CSSProperties } from "react";
import MarkdownViewer from "@components/common-components/markdown-viewer";

const { TextArea } = Input;

export enum InputSize {
  Small,
  Medium,
  Large,
}
const renderInputSize = (size: InputSize) => {
  switch (size) {
    case InputSize.Small:
      return {
        height: 30,
        fontSize: 12,
      };
    case InputSize.Medium:
      return {
        height: 45,
        fontSize: 14,
      };
    case InputSize.Large:
      return {
        height: 60,
        fontSize: 14,
      };
  }
};
const renderTextAreaSize = (size: InputSize) => {
  switch (size) {
    case InputSize.Small:
      return {
        height: 55,
        fontSize: 12,
      };
    case InputSize.Medium:
      return {
        height: 70,
        fontSize: 12,
      };
    case InputSize.Large:
      return {
        height: 90,
        fontSize: 14,
      };
  }
};

export interface CommonInputProps {
  placeholder?: string;
  size?: InputSize;
  inputType?: "text" | "password" | "textarea" | "number" | "tel" | "email";
  variant?: "light" | "dark" | "transparent";
  value?: string;
  disabled?: boolean;
  isRequired?: boolean;
  isValid?: boolean;
  readOnly?: boolean;
  onChange?: (
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onChangeNumber?: (event?: string) => void;
  onInput?: (event) => void;
  onBlur?: (event) => void;
  onFocus?: (event) => void;
  keypress?: (event) => void;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  label?: string;
  autoSizeCheck?: boolean | any;
  name?: string;
  id?: string;
  autoFocus?: boolean;
  round?: boolean;
  maxlength?: number;
  min?: string;
  max?: string;
  type?: string;
  defaultValue?: any;
  className?: string;
  isRsClass?: string;
  containerClassName?: string;
  icon?: React.ReactNode;
  formatter?: (value: string) => string;
  parser?: (value: string) => string;
  iconClasses?: string;
  onPressEnter?: (event) => void;
  cyId?: string;
  ref?: any;
  allowClear?: boolean;
  onKeyDown?: (event) => void;
  incrementOnWheel?: boolean;
  style?: CSSProperties;
  isMarkdown?: boolean;
}

export const CommonInput: React.FC<CommonInputProps> = (
  props: CommonInputProps,
) => {
  const inputSize = props.size || InputSize.Medium;
  const label = props.label || null;
  const autoSizeCheck = props.autoSizeCheck || false;
  const { disabled } = props;

  const handleCustomPaste = (event) => {
    if (navigator?.userAgent.includes("Firefox")) {
      return;
    }
    event.preventDefault();
    const pasted = event.clipboardData.getData("text").replaceAll("\n", " ");
    event.clipboardData.setData("Text", pasted);
    const currentValue = event.target.value;
    event.target.value =
      currentValue.slice(0, event.target.selectionStart) +
      pasted +
      currentValue.slice(event.target.selectionEnd);
    props.onChange(event);
  };

  /*
    Quick hacky way to render Markdown from LLM output
  */
  if (props.isMarkdown === true) {
    return (
      <div
        className={classnames(
          styles.customInputMarkdown,
          props.containerClassName,
        )}
      >
        {label && (
          <Label
            className={props?.isRsClass}
            htmlFor={props.name}
            id={props.id}
          >
            {label}
          </Label>
        )}
        <MarkdownViewer text={props.value} type="none" />
      </div>
    );
  }
  return (
    <div className={classnames(styles.customInput, props.containerClassName)}>
      {label && (
        <Label className={props?.isRsClass} htmlFor={props.name} id={props.id}>
          {label}
        </Label>
      )}
      {props.inputType === "textarea" ? (
        <TextArea
          name={props.name}
          value={props.value || props.defaultValue}
          placeholder={props.placeholder}
          autoSize={autoSizeCheck}
          onPaste={handleCustomPaste}
          onChange={props.onChange ? props.onChange : null}
          onInput={props.onInput ? props.onInput : null}
          style={{
            ...props.style,
            ...renderTextAreaSize(inputSize),
            borderRadius: "5px",
            border: "1px solid #E0E0E0",
            fontSize: "16px",
          }}
          autoFocus={props.autoFocus}
          readOnly={props.readOnly}
          disabled={props.disabled}
          className={classnames(
            {
              [styles.light]: props.variant === "light",
              [styles.dark]: props.variant === "dark",
              [styles.transparent]: props.variant === "transparent",
            },
            styles.roundInput,
            props.className,
          )}
        />
      ) : props.inputType === "number" ? (
        <Input
          data-cy={props.cyId}
          onPressEnter={props.onPressEnter}
          onInput={props.onInput ? props.onInput : null}
          onBlur={props.onBlur ? props.onBlur : null}
          onKeyDown={props.onKeyDown}
          onFocus={props.onFocus ? props.onFocus : null}
          type={"number"}
          name={props.name}
          value={props.value || props.defaultValue}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          prefix={props.prefix || null}
          suffix={props.suffix || null}
          onChange={props.onChange ? props.onChange : null}
          style={renderInputSize(inputSize)}
          autoFocus={props.autoFocus}
          maxLength={props.maxlength}
          readOnly={props.readOnly}
          ref={props.ref}
          max={props.max}
          min={props.min}
          onWheel={
            !props.incrementOnWheel
              ? (event) => event.currentTarget.blur()
              : () => null
          }
          className={classnames(
            {
              [styles.light]: props.variant === "light",
              [styles.dark]: props.variant === "dark",
              [styles.transparent]: props.variant === "transparent",
            },
            styles.roundInput,
            props.className,
          )}
          disabled={props.disabled}
          required={props.isRequired}
        />
      ) : props.inputType === "password" ? (
        <Input.Password
          data-cy={props.cyId}
          onPressEnter={props.onPressEnter}
          onInput={props.onInput ? props.onInput : null}
          onBlur={props.onBlur ? props.onBlur : null}
          onFocus={props.onFocus ? props.onFocus : null}
          type={props.inputType || "text"}
          name={props.name}
          prefix={props.prefix || null}
          suffix={props.suffix || null}
          value={props.value || props.defaultValue}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          onChange={props.onChange ? props.onChange : null}
          style={renderInputSize(inputSize)}
          autoFocus={props.autoFocus}
          maxLength={props.maxlength}
          readOnly={props.readOnly}
          ref={props.ref}
          className={classnames(
            {
              [styles.light]: props.variant === "light",
              [styles.dark]: props.variant === "dark",
              [styles.transparent]: props.variant === "transparent",
            },
            styles.roundInput,
            props.className,
          )}
          disabled={props.disabled}
          required={props.isRequired}
        />
      ) : (
        <Input
          data-cy={props.cyId}
          onPressEnter={props.onPressEnter}
          onInput={props.onInput ? props.onInput : null}
          onBlur={props.onBlur ? props.onBlur : null}
          onFocus={props.onFocus ? props.onFocus : null}
          type={props.inputType || "text"}
          name={props.name}
          value={props.value || props.defaultValue}
          defaultValue={props.defaultValue}
          prefix={props.prefix || null}
          suffix={props.suffix || null}
          placeholder={props.placeholder}
          onChange={props.onChange ? props.onChange : null}
          style={renderInputSize(inputSize)}
          autoFocus={props.autoFocus}
          maxLength={props.maxlength}
          readOnly={props.readOnly}
          ref={props.ref}
          allowClear={props.allowClear}
          className={classnames(
            {
              [styles.light]: props.variant === "light",
              [styles.dark]: props.variant === "dark",
              [styles.transparent]: props.variant === "transparent",
            },
            styles.roundInput,
            props.className,
          )}
          disabled={props.disabled}
          required={props.isRequired}
        />
      )}
      <span className={props.iconClasses}>{props.icon && props.icon}</span>
    </div>
  );
};

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";

import PublicLayout from "./main-layout/public-layout";
import PrivateLayout from "./main-layout/private-layout";
import AdminLayout from "@components/layout/admin-layout/AdminLayout";
import SuperAdminLayout from "@components/layout/superadmin-layout/SuperAdminLayout";
import SuperAdminLogin from "@components/pages/SuperAdmin/SuperAdminLogin";

//  Routes that are always accessible (both logged-in & guests can visit)
const OPEN_ROUTES = [
  constRoute.root,
  constRoute.TermOfUse,
  constRoute.softwareAttribution,
  constRoute.privacyPolicy,
  constRoute.faqs,
  constRoute.pricing,
];

//  Routes only for guests (should be blocked if logged in)
const GUEST_ONLY_ROUTES = [
  constRoute.login,
  constRoute.signup,
  constRoute.verifyEmail,
  constRoute.forgetPassword,
  constRoute.checkEmail,
  constRoute.setNewPassword,
  constRoute.resetPasswordSuccessfully,
];

//  Routes that require authentication
const AUTH_REQUIRED_ROUTES = [
  constRoute.profile,
  constRoute.existingProject,
  constRoute.home,
  constRoute.projectResult,
  constRoute.playground,
  constRoute.admin,
  constRoute.masteradminDashboard,
  constRoute.masteradminUsers,
  constRoute.masteradminPromptPanel,
  constRoute.masteradminResource,
  constRoute.masteradminBilling,
];

//  Routes allowed without authentication (subset of OPEN_ROUTES & GUEST_ONLY)
const ALLOWED_WITHOUT_AUTH = [
  ...OPEN_ROUTES,
  ...GUEST_ONLY_ROUTES,
  constRoute.admin, // Admin login page
  constRoute.masteradminLogin, // Super admin login page
  constRoute.masterAdminRoot, // Super admin login page
  constRoute.masteradminUsers,
  constRoute.masteradminPromptPanel,
  constRoute.masteradminResource,
  constRoute.masteradminBilling,
];

const DefaultLayout = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ready, setReady] = useState(false);

  const {
    user: { loadUserInfo, setUserType },
  } = useStore(null);

  const token = localStorage.getItem("token");
  const masterToken = localStorage.getItem("master_token");

  const isResetPasswordPage = location.pathname.includes(
    constRoute.resetPasswordSuccessfully,
  );
  const isMasterAdminLayout = location.pathname.includes("/masteradmin"); //  Corrected & retained
  const isMasterAdminLoginPage =
    location.pathname === constRoute.masteradminLogin; //  Explicit check
  const isUserLayout = !isMasterAdminLayout; //  For regular user layouts

  useEffect(() => {
    let plan = localStorage.getItem("plan");
    setUserType(plan && plan !== "undefined" ? plan : "premium");
  }, [setUserType]);

  useEffect(() => {
    if (isMasterAdminLayout) return;

    if (!token) {
      if (!ALLOWED_WITHOUT_AUTH.includes(location.pathname)) {
        navigate(constRoute.root);
        localStorage.removeItem("token");
      }
    } else {
      // Redirect to home if a logged-in user navigates to `/`
      if (isUserLayout && location.pathname === constRoute.root) {
        navigate(constRoute.home);
      }

      // Prevent logged-in users from accessing guest-only routes
      if (GUEST_ONLY_ROUTES.includes(location.pathname)) {
        navigate(constRoute.home);
      }
    }

    setReady(true);
  }, [token, navigate, location.pathname]);

  useEffect(() => {
    if (!masterToken) {
      if (isMasterAdminLayout) {
        localStorage.removeItem("master_token");
        navigate(constRoute.masteradminLogin);
      }
    } else if (isMasterAdminLoginPage) {
      navigate(constRoute.masteradminDashboard);
    }

    setReady(true);
  }, [masterToken, navigate, isMasterAdminLayout, isMasterAdminLoginPage]);

  useEffect(() => {
    if (isMasterAdminLayout) return;

    if (!ALLOWED_WITHOUT_AUTH.includes(location.pathname) && ready) {
      loadUserInfo();
    }
  }, [location.pathname, loadUserInfo]);

  if (!ready) {
    return null;
  }

  // **Routing Cases**
  if (isMasterAdminLayout) {
    return masterToken ? <SuperAdminLayout /> : <SuperAdminLogin />;
  }

  if (!token || isResetPasswordPage) {
    return <PublicLayout />;
  }

  if (location.pathname.includes(constRoute.admin)) {
    return <AdminLayout />;
  }

  return <PrivateLayout />;
});

export default DefaultLayout;

import React from "react";

const InfoIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.77348 1.90357C9.14789 1.69277 9.57031 1.58203 9.99998 1.58203C10.4296 1.58203 10.8521 1.69277 11.2265 1.90357C11.6009 2.11436 11.9146 2.41809 12.1375 2.78546L12.1399 2.78942L19.1982 14.5728L19.205 14.5843C19.4233 14.9623 19.5388 15.3909 19.54 15.8274C19.5412 16.2639 19.4281 16.6932 19.2119 17.0724C18.9958 17.4517 18.6841 17.7677 18.3078 17.9891C17.9316 18.2105 17.504 18.3295 17.0675 18.3343L17.0583 18.3344L2.93248 18.3343C2.49598 18.3295 2.06834 18.2105 1.69212 17.9891C1.31589 17.7677 1.00419 17.4517 0.788018 17.0724C0.571848 16.6932 0.458748 16.2639 0.459971 15.8274C0.461193 15.3909 0.576695 14.9623 0.794985 14.5843L0.801754 14.5728L7.86247 2.78545C8.0853 2.41809 8.39908 2.11436 8.77348 1.90357ZM9.99998 3.2487C9.85675 3.2487 9.71595 3.28561 9.59115 3.35588C9.46691 3.42582 9.3627 3.52647 9.28849 3.64818L2.23555 15.4225C2.16457 15.5473 2.12703 15.6884 2.12663 15.8321C2.12622 15.9776 2.16392 16.1207 2.23598 16.2471C2.30804 16.3735 2.41194 16.4788 2.53735 16.5526C2.66166 16.6258 2.80281 16.6654 2.94697 16.6677H17.053C17.1971 16.6654 17.3383 16.6258 17.4626 16.5526C17.588 16.4788 17.6919 16.3735 17.764 16.2471C17.836 16.1207 17.8737 15.9776 17.8733 15.8321C17.8729 15.6884 17.8354 15.5474 17.7644 15.4225L10.7125 3.64984C10.7121 3.64929 10.7118 3.64874 10.7115 3.64818C10.6373 3.52647 10.533 3.42582 10.4088 3.35588C10.284 3.28561 10.1432 3.2487 9.99998 3.2487Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 6.66797C10.4606 6.66797 10.8337 7.04106 10.8337 7.5013V10.8346C10.8337 11.2949 10.4606 11.668 10.0003 11.668C9.54009 11.668 9.16699 11.2949 9.16699 10.8346V7.5013C9.16699 7.04106 9.54009 6.66797 10.0003 6.66797Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16699 14.1654C9.16699 13.7051 9.54009 13.332 10.0003 13.332H10.0087C10.4689 13.332 10.842 13.7051 10.842 14.1654C10.842 14.6256 10.4689 14.9987 10.0087 14.9987H10.0003C9.54009 14.9987 9.16699 14.6256 9.16699 14.1654Z"
        fill="black"
      />
    </svg>
  );
};

export default InfoIcon;

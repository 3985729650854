import axios from "axios";
import { BaseApi } from "../baseApi";
import { getAuthorizationHeader } from "../common-utils";
import { baseUrl } from "../const";

class UserApi extends BaseApi {
  onUserLogin = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}signin`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  onSignUpUser = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}signup`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  sendResendEmail = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}sendResetEmail`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  senddEmailVerification = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}verifyEmail`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  resetPassword = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}resetPassword`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  onProjectSave = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}saveProject`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  onConceptNote = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}conceptNote`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  getCurrentUserDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}getCurrent`, {
        headers: { Authorization: getAuthorizationHeader() },
        cancelToken: this.cancelToken,
      });

      const data = JSON.stringify(response.data);
      localStorage.setItem("userData", data);

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  onGetExistingProject = async () => {
    try {
      const response = await axios.get(`${baseUrl}getProjects`, {
        headers: { Authorization: getAuthorizationHeader() },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };
  onDeleteProject = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}deleteProject`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  onGetData = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}getData`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });
      localStorage.setItem(
        "tokens",
        response.data["tokens_remaining/tokens_allotted"],
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  onGenerateProject = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}generateReport`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  getProjectReportPdf = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}getPdfReport`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        responseType: "blob",
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };
  getCongratulationResults = async (data) => {
    try {
      const response = await axios.post(
        `${baseUrl}getCNGraderSummary`,
        {
          functionality: "concept note evaluator",
          ...data,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
            "Content-Type": "application/json",
          },
          cancelToken: this.cancelToken,
        },
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getRationalAdvisorResults = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}getCRSummary`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };
  getDraftResults = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}getCNNarratives`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getPSSummary = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}getPSSummary`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getTCSummary = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}getTCSummary`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getNOPSummary = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}getNOPSummary`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getPromptGuidePdf = async () => {
    try {
      const response = await axios.get(`${baseUrl}getPromptGuide`, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
        responseType: "blob",
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  private static async hashObjectWithSecretKey(data, secretKey) {
    const dataStr = JSON.stringify(data);

    const encoder = new TextEncoder();
    const dataWithSecret = encoder.encode(dataStr + secretKey);

    const hashBuffer = await crypto.subtle.digest("SHA-256", dataWithSecret);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    return hashHex;
  }

  static async sendResultToEmail(data) {
    try {
      const response = await axios.post(`${baseUrl}emailReport`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "x-data-hash": await this.hashObjectWithSecretKey(data, "jaicpapp"),
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async checkInputTokensValid(data) {
    try {
      const response = await axios.post(`${baseUrl}checkTokens`, data, {
        headers: {
          Authorization: getAuthorizationHeader(),
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getUserGuide() {
    try {
      const response = await axios.get(`${baseUrl}getUserGuide`, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
          Authorization: getAuthorizationHeader(),
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default UserApi;

import React from "react";
import styles from "./project-structure-welcome.module.scss";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import style from "@components/pages/project/project-name/style.module.scss";
import GoBack from "@commonComponents/go-back";

const ProjectStructureWelcome = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.welcomeModal}>
        <div className={styles.welcomeModalContent}>
          <h1 className={styles.welcomeModalTitle}>
            Welcome to the Project Structuring Advisor!
          </h1>
          <p className={styles.welcomeModalDesc}>
            This tool offers guidance on crafting a Renewable Energy Project
            Plan, but can also be used for crafting "bankable" adaptation
            projects. Not only will it pinpoint missing criteria in your
            narratives, but it also provides actionable feedback to enhance your
            plan. Thank you for trusting Climate Finance Co-pilot to contribute
            to this important work!
          </p>
          <p className={styles.welcomeModalGreetings}>
            Warm regards, The Janus Team
          </p>
        </div>
        <div
          className={styles.welcomeModalButton}
          onClick={() => navigate(constRoute.projectName + "/advisor")}
        >
          Continue
        </div>
      </div>

      <div className={style.btnDiv}>
        <div className={style.twoBtnDiv}>
          <div className={style.goBackBtnDiv}>
            <GoBack
              onClick={constRoute?.selectFunction}
              className={style.goBtn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectStructureWelcome;

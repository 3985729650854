import GoBack from "@commonComponents/go-back";
import style from "@components/pages/project/project-name/style.module.scss";
import { constRoute } from "@utils/route";
import { useNavigate } from "react-router-dom";
import styles from "./rational-advisor-welcome.module.scss";

const RationalAdvisorWelcome = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.welcomeModal}>
        <div className={styles.welcomeModalContent}>
          <h1 className={styles.welcomeModalTitle}>
            Welcome to the Climate Rationale Advisor!
          </h1>
          <p className={styles.welcomeModalDesc}>
            <strong>Purpose:</strong> The purpose of this tool is to enable the
            grant writer to develop the most compelling Climate Rationale
            possible. It will assist in the development of evidence-based
            arguments demonstrating that climate change is the primary factor
            contributing to the problem the project aims to address.
          </p>
          <p className={styles.welcomeModalDesc}>
            It will supply reliable sources and references to strengthen your
            argument and will identify any gaps in the logic of your rationale
            and suggest corrections.
          </p>
          <p className={styles.welcomeModalDesc}>
            <strong>Input:</strong> Before initiating this workflow, ensure that
            you have gathered the following components: - Your problem
            "identification narrative" (backed by climate science) - Project
            objective, outcomes, and activities - The national climate
            strategies that the project supports - Monitoring and evaluation
            regimen.
          </p>
          <p className={styles.welcomeModalDesc}>
            Should you have any questions or concerns, please feel free to
            contact us at any time.{" "}
          </p>
          <p className={styles.welcomeModalGreetings}>The Janus Team</p>
        </div>
        <div
          className={styles.welcomeModalButton}
          onClick={() => navigate(constRoute.projectName + "/rational-advisor")}
        >
          Continue
        </div>
      </div>

      <div className={style.btnDiv}>
        <div className={style.twoBtnDiv}>
          <div className={style.goBackBtnDiv}>
            <GoBack
              onClick={constRoute?.selectFunction}
              className={style.goBtn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RationalAdvisorWelcome;

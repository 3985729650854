import { FC, memo, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useTokensContext } from "@components/layout/main-layout/private-layout";
import PlaygroundForm from "@components/pages/project/Playground/PlaygroundForm";
import { ChatRole, IChat } from "@components/pages/project/Playground/types";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { useForm } from "antd/es/form/Form";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { io } from "socket.io-client";
import { baseSocketUrl } from "@api/const";
import { ModalDefaultTool } from "@commonComponents/default-tool-modal";
import { preferencesApi } from "@api";
import { notification } from "antd";
import { FileV2 } from "../../../../types/common-interfaces";

interface AdvisorFormProps {}
const formQuestions = [
  {
    q: <span>Hello! How Can I Help You Today?</span>,
    result: <span>Your Results Below:</span>,
    limit: 1000,
    placeholder:
      "Here are a few things to know when utilizing this tool:\n" +
      "\n" +
      "• Enter any query or task into the box located at the bottom of this page.\n" +
      "• The Playground will remember your queries/tasks, enabling you to ask follow-up questions as needed.\n" +
      "• Be aware that once you navigate from this page, your session will be cleared from memory, so make sure to save any information outputs.\n" +
      "• For assistance on crafting effective prompts, refer to the prompt writing tips guide situated at the top right of this page.",
  },
];

const convertFileToBase64 = (file): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const data = reader.result.toString();
      resolve(data.split(",")[1]);
    };
    reader.onerror = (error) => reject(error);
  });
};

const resizeImageAsync = (file, maxWidth, maxHeight): Promise<string> => {
  return new Promise((resolve, reject) => {
    resizeImage(file, maxWidth, maxHeight, (blob) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        const base64Image = e.target.result;
        resolve(base64Image.toString());
      };

      reader.readAsDataURL(blob);
    });
  });
};

function resizeImage(file, maxWidth, maxHeight, callback) {
  const img = new Image();
  const reader = new FileReader();

  reader.onload = function (event) {
    img.src = event.target.result as string;
  };

  img.onload = function () {
    let width = img.width;
    let height = img.height;

    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }

    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    canvas.toBlob(function (blob) {
      callback(blob);
    }, "image/jpeg");
  };

  reader.readAsDataURL(file);
}

const Playground: FC<AdvisorFormProps> = () => {
  const nav = useNavigate();
  const socketRef = useRef(null);
  const { type, step } = useParams();
  const { pathname } = useLocation();
  const [form] = useForm();
  const [question, setQuestion] = useState("");
  const [url, setUrl] = useState("");
  const [isUrlBoxOpen, setIsUrlBoxOpen] = useState(false);
  const [chat, setChat] = useState<IChat[]>([]);
  const [research, setResearch] = useState("");
  const [scoring, setScoring] = useState("");
  const [country, setCountry] = useState("");
  const [sector, setSector] = useState("");
  const [annex, setAnnex] = useState("");

  // sureco poc
  const [section, setSection] = useState("");
  const [subsection, setSubsection] = useState("");
  const [subfolder, setSubfolder] = useState("");

  const [climateRationalePrompt, setClimateRationalePrompt] = useState("");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedDocuments, setSelectedDocuments] = useState<FileV2[] | null>(
    null,
  );
  const [searchParams] = useSearchParams();
  const isContinueMode = searchParams.get("mode") === "continue";
  const [continueChatText, setContinueChatText] = useState("");
  const urlSessionId = searchParams.get("session");

  const projectName = localStorage.getItem("projectName");
  const results = JSON.parse(
    localStorage.getItem("totalResultsAdvisor" + projectName),
  );

  const {
    user: { handleGetGuidePdf, getSingleProjectData },
    modelType: { changeModelType, getSelectedModelType },
  } = useStore(null);
  const [initialInput, setInitialInput] = useState(null);
  const [isDefaultToolModalOpen, setIsDefaultToolModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questions, setQuestions] = useState("");
  const [resultsData, setResultsData] = useState(results || "");
  const [input, setInput] = useState("");
  const TokenContext = useTokensContext();

  const getProjectData = async () => {
    const data = JSON.stringify({ functionality: "playground" });

    await getSingleProjectData(data).then((state) => {
      if (state) {
        TokenContext?.setTokens(state["tokens_remaining/tokens_purchased"]);
      }
    });
  };

  useEffect(() => {
    if (isContinueMode) {
      const storedData = localStorage.getItem("continue_in_playground");

      if (!storedData) {
        console.warn("No continue_in_playground data found. Redirecting...");
        nav(constRoute.playground, { replace: true });
        return;
      }

      const parsedData = JSON.parse(storedData);

      // Ensure session ID matches
      if (!urlSessionId || parsedData.sessionId !== urlSessionId) {
        console.warn("Invalid session detected. Cleaning up URL.");
        nav(constRoute.playground, { replace: true });
        return;
      }

      setContinueChatText(parsedData.message || "");

      // Change model
      changeModelType(parsedData.model || "Titan");

      // localStorage.removeItem("continue_in_playground");
    }
  }, [isContinueMode, urlSessionId, nav]);

  const isFormPage = type === "form";

  const isSurecoPoc = section !== "" && subsection !== "" && subfolder !== "";

  const handleSubmit = async (
    values,
    regenConfig: {
      isRegen?: boolean;
      lastMessage?: any;
      configOnlyMessage?: boolean;
    } = {},
  ) => {
    const inputVal = regenConfig.isRegen === true ? values.q : input;
    setQuestion(form?.getFieldValue("q"));
    setResultsData("");
    setIsSubmitting(true);

    if (!inputVal && !isSurecoPoc) {
      setIsSubmitting(false);
      return;
    }

    setChat((prev) =>
      prev.map((el) => {
        return { ...el, isLastChatMessage: false };
      }),
    );

    let message = {
      id: chat[chat.length - 1]?.id ? chat[chat.length - 1]?.id + 1 : 1,
      role: ChatRole.USER,
      message: inputVal,
      image: selectedImage,
      url,
      containsFile: selectedImage !== null || selectedDocuments !== null,
      files:
        selectedDocuments !== null
          ? selectedDocuments?.map((e) => e.filename)
          : [],
      isLastChatMessage: false,
    };

    const answer = {
      id: chat[chat.length - 1]?.id ? chat[chat.length - 1]?.id + 2 : 2,
      role: ChatRole.CHAT,
      message: "",
      isLastChatMessage: true,
    };

    const email = localStorage.getItem("email");

    setInitialInput(values?.q);

    const data: any = {
      conversation:
        chat.length === 0
          ? [
              {
                role: "user",
                content: [
                  {
                    type: "text",
                    text: inputVal,
                  },
                ],
              },
            ]
          : [
              ...chat.map((el) => {
                const res = {
                  role: el.role === ChatRole.CHAT ? "assistant" : "user",
                  content: [
                    {
                      type: "text",
                      text: el.message,
                    },
                  ],
                };

                if (el?.image_url) {
                  res.content.push({
                    type: "image_url",
                    // @ts-ignore
                    image_url: { url: el?.image_url },
                  });
                }
                return res;
              }),
              {
                role: "user",
                content: [
                  {
                    type: "text",
                    text: inputVal,
                  },
                ],
              },
            ],
      email,
      model: getSelectedModelType ?? "Titan",
    };

    if (isContinueMode) {
      const storedData = localStorage.getItem("continue_in_playground");
      const parsedData = JSON.parse(storedData);

      data.continue_playground_data = {
        project_name: parsedData.project_name,
        tool: parsedData.tool,
        section: parsedData.section,
      };
    }

    if (url !== "") {
      data.url = url;
    }

    if (section !== "" && subsection !== "" && subfolder !== "") {
      data.section = section;
      data.subsection = subsection;
      data.subfolder = subfolder;
    }

    // if (scoring !== "") {
    //   data.scoring = scoring;
    // }
    //
    // if (research !== "") {
    //   data.research = research;
    // }
    //
    // if (sector !== "") {
    //   data.sector = sector;
    // }
    //
    // if (annex !== "") {
    //   data.annex = annex;
    // }
    //
    // if (climateRationalePrompt !== "") {
    //   data.climateRationalePrompt = climateRationalePrompt;
    // }
    //
    // if (country !== "") {
    //   data.country = country;
    // }
    //
    // if (!inputVal && country !== '' && sector !== '') {
    //   data.conversation = []
    //   message.message = ''
    // }

    const socket = io(baseSocketUrl, {
      transports: ["websocket", "polling"],
    });

    if (selectedImage) {
      const base64Image = await resizeImageAsync(selectedImage, 1024, 768);

      data.conversation[data.conversation.length - 1].content.push({
        type: "image_url",
        // @ts-ignore
        image_url: { url: base64Image },
      });

      message["image_url"] = base64Image;
    }

    if (selectedDocuments?.length > 0) {
      data.files = selectedDocuments;
    }

    if (isSurecoPoc && selectedDocuments?.length > 0) {
      data.conversation = [];
    }

    if (
      regenConfig.isRegen === true &&
      regenConfig.configOnlyMessage !== true
    ) {
      message = regenConfig.lastMessage;
    }

    setChat((prev) => [...prev, message, answer]);
    socket.emit("playground", data);

    const responseTimeout = setTimeout(() => {
      socket.disconnect();
      localStorage.removeItem("isStopGeneration");
      setIsSubmitting(false);

      // remove the last message as it's created before model's response
      const fallBackChat = [...chat];
      fallBackChat.pop();
      setChat(fallBackChat);

      setErrorMessage(
        "Timeout: The server took too long to respond, please wait 5 minutes and try again by refreshing the page.",
      );
    }, 60000);

    socketRef.current = socket;
    setInput("");
    setIsUrlBoxOpen(false);
    setSelectedImage(null);
    setSelectedDocuments(null);
    setResultsData("");
    setErrorMessage("");

    socket.on("error_message", (err) => {
      console.log("err", err);

      clearTimeout(responseTimeout);
      socket.disconnect();
      localStorage.removeItem("isStopGeneration");
      setIsSubmitting(false);

      // remove the last message as it's created before model's response
      const fallBackChat = [...chat];
      fallBackChat.pop();
      setChat(fallBackChat);

      setErrorMessage(err);
    });

    socket.on("answer", (answer) => {
      clearTimeout(responseTimeout);
      const isStopGeneration = localStorage.getItem("isStopGeneration");
      if (!isSubmitting && answer) {
        setIsSubmitting(true);
      }

      if (isStopGeneration || answer === "#####") {
        socket.disconnect();
        localStorage.removeItem("isStopGeneration");
        setIsSubmitting(false);
        return;
      }

      setResultsData(answer);
      setChat((prev) =>
        prev.map((el) => {
          if (el.id === prev[prev.length - 1].id) {
            return {
              id: prev[prev.length - 1].id + 1,
              role: ChatRole.CHAT,
              message: prev[prev.length - 1].message + answer,
              isLastChatMessage: true,
            };
          } else {
            return el;
          }
        }),
      );
    });

    socket.on("disconnect", () => {
      clearTimeout(responseTimeout);
      setIsSubmitting(false);
      getProjectData();
    });
  };

  useEffect(() => {
    getProjectData();
  }, []);

  useEffect(() => {
    const storedPlaygroundUseCount = localStorage.getItem("playgroundUseCount");
    if (!storedPlaygroundUseCount) {
      return;
    }

    // presence of this value means that the modal is answered before! yay!
    const defaultTool = localStorage.getItem("defaultTool");

    if (parseInt(storedPlaygroundUseCount) >= 2 && defaultTool === null) {
      setIsDefaultToolModalOpen(true);
    }
  }, []);

  const stopGeneration = async () => {
    if (socketRef.current) {
      await socketRef.current.disconnect();
      await setIsSubmitting(false);
    }

    setChat((prev) =>
      prev.map((message, i) => {
        if (i !== chat.length - 1) {
          return message;
        } else {
          if (message.role === ChatRole.CHAT && message.message === "") {
            message.message = "__INTERNAL_STOPPED__";
          }

          return message;
        }
      }),
    );
  };

  const handleRegenerate = () => {
    const lastMessage = { ...chat[chat.length - 2] };
    const question = lastMessage.message;
    setChat(chat.slice(0, -2));
    setInput(question);
    form.setFieldValue("q", "");

    setQuestions(question);
    const values = {
      q: question,
    };

    handleSubmit(values, { isRegen: true, lastMessage });
  };

  const onDownloadPdf = async () => {
    await handleGetGuidePdf().then((blob) => {
      const url = window.URL.createObjectURL(blob);

      const newTab = window.open();
      newTab.document.write(
        '<iframe width="100%" height="100%" src="' +
          url +
          '"frameborder="0"></iframe>',
      );
      newTab.document.body.style.margin = "0";

      window.URL.revokeObjectURL(url);
    });
  };

  const handleSetDefaultTool = async () => {
    const res = await preferencesApi.setDefaultTool("playground");

    if ((res.message = "Default tool saved successfully")) {
      localStorage.setItem("defaultTool", "playground");
      notification.success({
        placement: "topRight",
        message: "Playground is now your default",
      });
    } else {
      notification.error({
        placement: "topRight",
        message: "Failed to set Playground default",
      });
    }

    setIsDefaultToolModalOpen(false);
  };

  const handleCloseDefaultToolModal = async () => {
    await preferencesApi.setDefaultTool("home");

    setIsDefaultToolModalOpen(false);
  };

  useEffect(() => {
    return () => {
      socketRef?.current?.disconnect();
    };
  }, []);

  return (
    <div style={{ padding: "30px 52px 12px" }}>
      <ModalDefaultTool
        isOpen={isDefaultToolModalOpen}
        handleSetDefaultTool={handleSetDefaultTool}
        handleClose={handleCloseDefaultToolModal}
      />
      <PlaygroundForm
        form={form}
        isContinueMode={isContinueMode}
        continueChatText={continueChatText}
        value={resultsData}
        isSubmitting={isSubmitting}
        initialState={initialInput}
        handleSubmit={handleSubmit}
        handleRegenerate={handleRegenerate}
        isFormPage={isFormPage}
        question={resultsData}
        setInput={(val: string) => setInput(val)}
        input={input}
        setChat={setChat}
        chat={chat}
        url={url}
        setUrl={setUrl}
        isUrlBoxOpen={isUrlBoxOpen}
        setIsUrlBoxOpen={setIsUrlBoxOpen}
        research={research}
        setResearch={setResearch}
        scoring={scoring}
        setScoring={setScoring}
        sector={sector}
        setSector={setSector}
        annex={annex}
        setAnnex={setAnnex}
        country={country}
        setCountry={setCountry}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        climateRationalePrompt={climateRationalePrompt}
        setClimateRationalePrompt={setClimateRationalePrompt}
        section={section}
        setSection={setSection}
        subsection={subsection}
        setSubsection={setSubsection}
        subfolder={subfolder}
        setSubfolder={setSubfolder}
        Text={formQuestions[0]?.q}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
        placeholder={formQuestions[0]?.placeholder}
        maxLength={formQuestions[0]?.limit}
        required={isSurecoPoc === true && selectedDocuments?.length > 0}
        disabled={isSurecoPoc === true && selectedDocuments?.length > 0}
        onDownloadPdf={onDownloadPdf}
        stopGeneration={() => stopGeneration()}
        isSurecoPoc={isSurecoPoc}
      />
    </div>
  );
};

export default memo(observer(Playground));

import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import {
  useNarrativesContext,
  useTokensContext,
} from "@components/layout/main-layout/private-layout";

import NoObjectionProcedureForm from "@components/pages/project/common-components/no-objection-procedure-form";
import CommonFooterButton from "@components/common-components/footer-button";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { useForm } from "antd/es/form/Form";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { DataType } from "types/data-type";
import { baseSocketUrl } from "@api/const";
import { notification } from "@utils/notifications";

interface NoObjectionProcedureFormProps {}
const formQuestions = [
  {
    q: (
      <span>
        Enter the Project’s <i>Overview</i> Below:
      </span>
    ),
    result: <span>Your Results Below:</span>,
    limit: 1000,
    placeholder:
      "Guidance to NDA office: Paste a summary of the project proposal, including objectives, a summary of activities, and expected outcomes here.\n" +
      "\n" +
      "Climate Finance Co-pilot will: Summarize this information and present it in a visually appealing format (e.g., bullet points, a table, or a mind map) to enhance readability and comprehension.",
  },
  {
    q: (
      <>
        Enter the Project’s <i>National Alignment</i> Below:
      </>
    ),
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Guidance to NDA office: Provide the project proponent's discussion on how the project aligns with the country's Country Programme and climate priorities, including its Nationally Determined Contributions (NDCs), national climate plans, and relevant policies here. Climate Finance Co-pilot will assess this narrative against the pre-defined criteria provided.\n" +
      "\n" +
      "Climate Finance Co-pilot will: Analyze the text and identify explicit or implicit references to national climate strategies and priorities. It will highlight these connections and assess the level of alignment, providing a clear and concise summary for the NDA.",
  },
  {
    q: (
      <>
        Enter the Project’s <i>Sector Plan Alignment</i> Below:
      </>
    ),
    result: <>Your Results Below:</>,
    placeholder:
      "Guidance to NDA office: Provide the project proponent's discussion on how the project/programme aligns with policy frameworks, national and sectoral priorities here. Climate Finance Co-pilot will assess this narrative against the pre-defined criteria provided.\n" +
      "\n" +
      "Climate Finance Co-pilot will: Analyze the text to identify and evaluate the alignment of the project with sectoral priorities, considering relevant policy documents and development plans. It will provide a summary of the analysis, highlighting any gaps or inconsistencies.",
    limit: 1000,
  },
  {
    q: (
      <>
        Enter the Project's <i>Technical Feasibility</i> Below:
      </>
    ),
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Guidance to NDA office: Provide the project proponent's discussion on why it is believed the project is technically feasible (i.e., not ill-conceived). Appraisal criteria to be used by the Belize National Climate Change Office (NCCO) include the following:\n" +
      "\n" +
      " • Technological maturity and proven track record\n" +
      " • Availability of skilled personnel and necessary infrastructure\n" +
      " • Realistic project timelines and resource allocation\n" +
      " • Consideration of potential technical risks and mitigation strategies\n" +
      "\n" +
      "Climate* Finance Co-pilot will: Assess the project's technical feasibility based on the proponent's input and the NCCO's appraisal criteria. It will analyze the project's design, technology choices, and implementation plan to identify potential technical challenges and provide recommendations for risk mitigation.",
  },
  {
    q: (
      <>
        Enter the Project's <i>Financial and Economic Viability</i> Below:
      </>
    ),
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Guidance to NDA office: Provide the project proponent's discussion on the project's financial and economic feasibility, including cost-effectiveness and the potential for sustainable development. Climate Finance Co-pilot will assess this narrative to detect any unsustainable financial implications for the country.\n" +
      "\n" +
      "Climate Finance Co-pilot will: Analyze the project's financial and economic aspects, including costs, funding sources, and potential economic benefits. It will assess the project's financial sustainability, considering factors such as return on investment, affordability, and potential for long-term economic growth.",
  },
  {
    q: (
      <>
        Enter the Project's <i>Environmental and Social Impacts</i> Below:
      </>
    ),
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Guidance to NDA office: Provide the project proponent's discussion on the project's potential environmental and social impacts, including risks and mitigation measures. Climate Finance Co-pilot will assess this narrative to detect any possible adverse environmental and social impacts for Belize.\n" +
      "\n" +
      "Climate Finance Co-pilot will: Analyze the project's potential environmental and social impacts, considering factors such as biodiversity, pollution, community health, and gender equality. It will identify potential risks and assess the adequacy of proposed mitigation measures.",
  },
  {
    q: (
      <>
        Enter the Project's <i>Stakeholder Engagement</i> Below:
      </>
    ),
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Guidance to NDA office: Provide the project proponent's discussion on stakeholder consultations and their feedback. Climate Finance Co-pilot will discuss how stakeholder engagement could be improved.\n" +
      "\n" +
      "Climate Finance Co-pilot will: Analyze the project proponent's discussion on stakeholder engagement, considering the inclusivity, transparency, and effectiveness of the consultation process. It will provide recommendations for improving stakeholder engagement, ensuring that diverse voices are heard and considered throughout the project lifecycle.",
  },
];

export const sectionsMap = [
  {
    text: "Project Overview",
    section: "PO",
  },
  {
    text: "National Alignment",
    section: "NA",
  },
  {
    text: "Sector Plan Alignment",
    section: "SPA",
  },
  {
    text: "Technical Feasibility",
    section: "TF",
  },
  {
    text: "Financial and Economic Viability",
    section: "FEV",
  },
  {
    text: "Environmental and Social Impacts",
    section: "ESI",
  },
  {
    text: "Stakeholder Engagement",
    section: "SE",
  },
];

const sections = sectionsMap.map((section) => section.section);

const NoObjectionProcedurePage: React.FC<
  NoObjectionProcedureFormProps
> = () => {
  const nav = useNavigate();
  const socketRef = useRef(null);
  const { type, step } = useParams();
  const [form] = useForm();
  const {
    user: { getSingleProjectData, projectNameData },
    modelType: { getSelectedModelType },
  } = useStore(null);
  const { setTokens } = useTokensContext();
  const { nopNarratives, setNopNarratives } = useNarrativesContext();
  const projectName =
    (projectNameData && JSON.parse(projectNameData).project_name) ||
    localStorage.getItem("projectName");

  const [initialInput, setInitialInput] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [resultsData, setResultsData] = useState("");
  const [isAnswer, setIsAnswer] = useState(false);
  const [sectionOutputPresent, setSectionOutputPresent] = useState(false);

  const results = isSubmitting
    ? ""
    : nopNarratives.find((el) => el.section === sections[+step - 1])?.res;

  const email = localStorage.getItem("email");
  const isFormPage = type === "form";

  const handleSubmit = useCallback(
    async (saveOnly = false) => {
      if (!isValid) return;
      if (isFormPage) {
        setResultsData("");

        const values = form.getFieldsValue();

        if (saveOnly === true) {
          setIsSaving(true);
        } else {
          setIsSubmitting(true);
        }

        const data: DataType = {
          section: sections[+step - 1],
          inputs:
            +step === 3
              ? { input: values?.q, q1a: values?.q1a }
              : { input: values?.q },
          project_name: projectName,
          email: email,
          model: getSelectedModelType ?? "Titan",
        };

        if (saveOnly === true) {
          data.save_only = true;
        }

        console.log("payload body ------------->", data);

        const socket = io(baseSocketUrl, {
          transports: ["websocket", "polling"],
        });

        socketRef.current = socket;
        socketRef?.current?.emit("noObjectionProcedure", data);

        let timeoutId;
        if (saveOnly === true) {
          timeoutId = setTimeout(() => {
            socket.disconnect();
            setIsSaving(false);
            notification.error("An error occurred: Save request timed out.");
          }, 10000); // 10 seconds timeout
        }

        socketRef?.current?.on("answer", (answer) => {
          const isStopGeneration = localStorage.getItem("isStopGeneration");

          if (
            saveOnly === true &&
            answer === "User input is saved successfully"
          ) {
            clearTimeout(timeoutId); // Cancel timeout if response received
            socket.disconnect();
            setIsSaving(false);
            localStorage.removeItem("isStopGeneration");
            notification.success("Project saved successfully!");
            return nav(constRoute?.existingProject);
          }

          if (!isSubmitting && answer) {
            setIsSubmitting(true);
          }

          if (answer) {
            setIsAnswer(true);
          }

          if (isStopGeneration || answer === "#####") {
            socket.disconnect();
            setIsSubmitting(false);
            setIsAnswer(false);
            localStorage.removeItem("isStopGeneration");
            return;
          }
          form.setFieldValue("q", (form?.getFieldValue("q") ?? "") + answer);
          localStorage.setItem(
            "totalResultsNoObjectionProcedure" + projectName,
            JSON.stringify({
              ...results,
              [`result${step}`]: (form?.getFieldValue("q") ?? "") + answer,
            }),
          );
        });
        socket.on("disconnect", () => {
          clearTimeout(timeoutId); // Prevent timeout from triggering after disconnect
          localStorage.removeItem("isStopGeneration");
          setIsSubmitting(false);
          setIsAnswer(false);
        });

        if (saveOnly !== true) {
          nav(constRoute?.noObjectionProcedureProject + "/results/" + step);
        }
      } else {
        if (saveOnly === true) {
          setIsSaving(true);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          setIsSaving(false);
          notification.success("Project saved successfully!");
          return nav(constRoute?.existingProject);
        }

        if (formQuestions.length < +step + 1) {
          nav(constRoute?.gcfCongratulation + "/no-objection-procedure");
          return;
        }
        setIsSubmitting(false);
        setIsAnswer(false);
        nav(constRoute?.noObjectionProcedureProject + "/form/" + (+step + 1));
      }
    },
    [type, step, getSelectedModelType],
  );

  const handleGoBack = () => {
    socketRef?.current?.disconnect();
    setIsSubmitting(false);
    setIsAnswer(false);
    // if (isSubmitting) return;
    if (+step === 1 && type == "results") {
      nav(constRoute?.noObjectionProcedureProject + "/form/1");

      return;
    }

    if (+step < 2) {
      nav(constRoute.projectName + "/no-objection-procedure");
      return;
    }

    if (type === "results") {
      nav(constRoute?.noObjectionProcedureProject + "/form/" + +step);
    } else {
      nav(constRoute?.noObjectionProcedureProject + "/results/" + (+step - 1));
    }
  };

  const handleSaveQuit = () => {
    handleSubmit(true);
  };

  const handleViewReport = () => {
    nav(constRoute?.noObjectionProcedureProject + "/results/" + +step);
  };

  const getProjectData = async () => {
    const email = localStorage.getItem("email");
    const data = {
      section: sections[+step - 1],
      project_name: projectName,
      functionality: "no objection procedure",
      email,
    };

    setIsLoading(true);
    setSectionOutputPresent(false);

    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    await getSingleProjectData(data)
      .then((state) => {
        setInitialInput(state?.questions);
        if (isFormPage) {
          form.setFieldValue(
            "q",
            state?.questions[`${sections[+step - 1]}_input`],
          );
          if (+step === 3) {
            form.setFieldValue("q1a", state?.questions.q1a);
          }
        } else {
          if (!isSubmitting) {
            form.setFieldValue(`q`, state?.generated_narratives?.find(Boolean));
          }
        }
        setTokens(state["tokens_remaining/tokens_purchased"]);
        setNopNarratives((prev) =>
          prev.map((el) => {
            if (el.section === sections[+step - 1]) {
              const obj = {
                ...el,
                res:
                  state?.generated_narratives?.map((el) => el).join("") || "",
              };

              if (obj.res !== "") {
                setSectionOutputPresent(true);
              }

              return obj;
            } else {
              return el;
            }
          }),
        );
      })
      .finally(() => setIsLoading(false));
    if (+step < 1 || formQuestions.length + 1 <= +step) {
      nav(constRoute?.gcfCongratulation + "/no-objection-procedure");
    }
  };

  const handleStopGenerate = () => {
    if (socketRef) {
      socketRef.current.disconnect();
    }
    setIsSubmitting(false);
    setIsAnswer(false);
  };

  useEffect(() => {
    localStorage.removeItem("isStopGeneration");
    getProjectData();

    // return () => {
    //   localStorage.removeItem("isStopGeneration");
    // };
  }, [step, type]);

  useEffect(() => {
    form.resetFields();
  }, [type === "results"]);

  useEffect(() => {
    socketRef?.current?.disconnect();
    form.resetFields();
    setIsSubmitting(false);
    setIsAnswer(false);
    setResultsData(
      nopNarratives.find((el) => el.section === sections[+step - 1])?.res || "",
    );
  }, [step]);

  useEffect(() => {
    return () => {
      socketRef?.current?.disconnect();
    };
  }, []);

  return (
    <div style={{ padding: "30px 52px 122px" }}>
      <NoObjectionProcedureForm
        form={form}
        value={results}
        isSubmitting={isSubmitting}
        initialState={initialInput}
        handleSubmit={handleSubmit}
        isFormPage={isFormPage}
        Text={
          isFormPage
            ? formQuestions[+step - 1]?.q
            : formQuestions[+step - 1]?.result || results[`result${step}`] || ""
        }
        placeholder={formQuestions[+step - 1]?.placeholder}
        maxLength={formQuestions[+step - 1]?.limit}
        disabled={type === "results"}
        isItalicFontStyle
        section={sections[+step - 1]}
        setIsValid={setIsValid}
        isValid={isValid}
        handleStopGenerate={handleStopGenerate}
        handleViewReport={handleViewReport}
        sectionOutputPresent={sectionOutputPresent}
        isLoading={isLoading}
        isAnswer={isAnswer}
      />
      <CommonFooterButton
        customStyle={{ left: 0 }}
        isLoadingSubmit={false}
        handleSubmit={handleSubmit}
        handlegoback={handleGoBack}
        isSubmitting={isSubmitting}
        isSaving={isSaving}
        handleSaveAndQuit={handleSaveQuit}
        isSubmit={type === "results"}
        // handleQuickNext={constRoute?.contextAndBaselineResults}
        form={form}
        setIsSubmitting={setIsSubmitting}
        disableSubmit={!isValid || isLoading}
      />
    </div>
  );
};

export default memo(observer(NoObjectionProcedurePage));

import down from "@assets/images/down.svg";
import { CommonInput } from "@components/common-components/input";
import QusestionSubmitionModal from "@components/pages/project/common-components/qusestion-submition-modal";
import { validateMessages } from "@utils/json-data";
import { Form, Select, Button, Upload } from "antd";
import { useForm } from "antd/es/form/Form";
import { observer } from "mobx-react";
import React, { FC, memo, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./style.css";
import style from "./style.module.scss";
import { useLocation } from "react-router-dom";
import Close from "@assets/icons/cross.svg";
import UploadIcon from "@assets/icons/upload.svg";
import classNames from "classnames";

type Props = {
  isLoading: boolean;
  firstStepForm: any;
  checkLengthOfInput: (str: any) => void;
  handleSubmit: (saveOnly: boolean) => void;
  handleViewReport: () => void;
  sectionOutputPresent: boolean;
  isFormPage: boolean;
  base64Images?: string[] | null;
  setBase64Images?: React.Dispatch<React.SetStateAction<string[] | null>>;
  allowUpload: boolean | undefined;
  selectedFiles: any[] | null;
  setSelectedFiles: React.Dispatch<React.SetStateAction<any[] | null>>;
  firstStepInitialAnswers: {
    q1a: string | null;
    q1b: string | null;
    q2: string | null;
  } | null;
};

const FirstRationalStep: FC<Props> = observer(
  ({
    firstStepInitialAnswers,
    firstStepForm,
    handleSubmit,
    isLoading,
    checkLengthOfInput,
    handleViewReport,
    sectionOutputPresent,
    allowUpload,
    isFormPage,
    selectedFiles,
    setSelectedFiles,
    base64Images,
    setBase64Images,
  }) => {
    const form = firstStepForm;
    const [submitModalShow, setSubmitModalShow] = useState(false);
    const { pathname } = useLocation();
    const type = pathname.split("/")[3];
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
      form.setFieldsValue(firstStepInitialAnswers);
    }, [firstStepInitialAnswers]);

    const isTablet = useMediaQuery({ query: "(max-width: 1200px)" });

    return (
      <div
        className={style.mainContainer}
        style={{ cursor: isLoading ? "wait" : "" }}
      >
        <div className={style.barContentContainer}>
          <div className={style.contentContainer}>
            <div className={style.innerContentContainer}>
              <div className={style.headingContainer}>
                <h1>Please Fill In the Blanks Below:</h1>
                {type === "results" ? null : (
                  <span
                    className={
                      isLoading === false && sectionOutputPresent
                        ? style.viewOutputButton
                        : style.viewOutputButtonDisabled
                    }
                    onClick={() =>
                      sectionOutputPresent ? handleViewReport() : null
                    }
                  >
                    View Last Output
                  </span>
                )}
              </div>

              <div className={style.dataContentBox}>
                <Form
                  className={style.formData}
                  form={form}
                  autoComplete="false"
                  onFinish={handleSubmit}
                  validateMessages={validateMessages}
                  layout="vertical"
                  // TODO: removed input limit until further notice
                  // maxlength={maxLength * 10}
                  // onChange={(e) => {
                  //   checkLengthOfInput(e.target.value);
                  // }}
                >
                  <div
                    className={style.questionOne}
                    style={{ marginBottom: "-30px" }}
                  >
                    <Form.Item
                      label={
                        <span
                          style={{
                            color: "#000",
                            fontFamily: "Inter",
                            fontSize: "17px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "22px",
                          }}
                        >
                          1a) Project Region Or Country Name
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      name={"q1a"}
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <CommonInput
                        inputType="textarea"
                        disabled={isLoading}
                        autoSizeCheck={{ minRows: 7, maxRows: 7 }}
                        className={style.emailInput}
                      />
                    </Form.Item>
                    <div style={{ marginTop: isTablet ? "-20px" : "" }}>
                      <Form.Item
                        label={
                          <span style={{ paddingTop: "5px" }}>
                            1b) What Is The Sector?
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        name={"q1b"}
                      >
                        <Select
                          placeholder="Choose sector"
                          style={{
                            width: "100%",
                            textAlign: "start",
                            maxWidth: "452px",
                            height: "52px !important",
                          }}
                          suffixIcon={<img src={down} alt={"down icon"} />}
                          className="select"
                          disabled={isLoading}
                          options={[
                            {
                              value:
                                "Buildings, cities, industries, and appliances",
                              label:
                                "Buildings, cities, industries, and appliances",
                            },
                            {
                              value: "Ecosystems and ecosystem services",
                              label: "Ecosystems and ecosystem services",
                            },
                            {
                              value: "Energy generation and access",
                              label: "Energy generation and access",
                            },
                            {
                              value: "Forests and land use",
                              label: "Forests and land use",
                            },
                            {
                              value: "Health, food, and water security",
                              label: "Health, food, and water security",
                            },
                            {
                              value: "Infrastructure and built environment",
                              label: "Infrastructure and built environment",
                            },
                            {
                              value: "Livelihoods of people and communities",
                              label: "Livelihoods of people and communities",
                            },
                            {
                              value: "Transport",
                              label: "Transport",
                            },
                            {
                              value: "Multi-sector",
                              label: "Multi-sector",
                            },
                          ]}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item
                    label={
                      <span style={{ marginTop: "28px" }}>
                        2. Briefly Discuss Known Current And Projected Climate
                        Vulnerabilities And Impacts.
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    colon={false}
                    required
                    htmlFor="q2"
                  />
                  <div className={style.textareaWrapper}>
                    {/*<Form.Item>*/}
                    {allowUpload && isFormPage && base64Images && (
                      <div className={style.previewWrapper}>
                        {base64Images.map((img, index) => (
                          <div key={index}>
                            <img src={img} alt="preview" />
                            <div
                              className={style.clearFile}
                              onClick={() => {
                                setBase64Images((prevImages: string[]) =>
                                  prevImages.filter((_, i) => i !== index),
                                );
                                setSelectedFiles((prevFiles) =>
                                  prevFiles.filter((_, i) => i !== index),
                                );
                              }}
                            >
                              <img src={Close} alt="Clear file" />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <Form.Item
                      name={"q2"}
                      // noStyle={true}
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <CommonInput
                        inputType="textarea"
                        disabled={isLoading}
                        style={{
                          boxShadow: "unset",
                        }}
                        // size={InputSize.Large}
                        className={classNames(style.textarea)}
                        placeholder={`Guidance below:\n\n The baseline scenario description should provide a detailed and concise overview of the current conditions in the project areas, including supporting data and statistics. This should include: \n\n Current Conditions: Describe the current state of the project areas, including climate-related hazards, infrastructure, and socio-economic conditions.\n\n Criteria for Selecting Project Areas: Outline the criteria used to select the project areas, including stakeholder engagement processes.\n\nQuantification of Climate Hazards: Quantify how climate hazards will affect the project's geographical area in terms of loss of lives, value of physical assets, livelihoods, and environmental or social/cultural assets. Demonstrate that climate change is the primary driver of adverse impacts and link climate change to these impacts using literature and data.\n\nProjected Climate Change Impacts: Outline expected climate impacts by a specific year (e.g., 2030, 2040) in the subject intervention area/country/region, citing relevant sources from the database.\n\nClimate Vulnerability Reduction: Explicitly state the project's intent to reduce climate vulnerability and improve resilience, highlighting the measures to be taken to mitigate climate-related risks and impacts.`}
                        autoSizeCheck={{ minRows: 25, maxRows: 40 }}
                      />
                    </Form.Item>
                    {/*</Form.Item>*/}
                  </div>
                </Form>
                {allowUpload && isFormPage && (
                  <div className={style.uploadButtonBlock}>
                    <div
                      style={{
                        border: "1px solid rgb(224, 224, 224)",
                        padding: "8px 10px",
                      }}
                    >
                      <div className={style.uploadButtonWrapper}>
                        <Upload
                          accept="image/*"
                          onChange={(info) => {
                            setSelectedFiles(info.fileList);
                          }}
                          multiple
                          fileList={[]}
                          itemRender={() => null}
                        >
                          <div className={style.uploadButtonBlockButton}>
                            <img alt="upload" src={UploadIcon} />
                            <span>Upload Images</span>
                          </div>
                        </Upload>
                      </div>
                    </div>
                  </div>
                )}
                {!isValid && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      position: "relative",
                      top: "45px",
                    }}
                  >
                    You have exceeded the maximum length for this field
                  </div>
                )}
              </div>
            </div>
          </div>
          <QusestionSubmitionModal open={submitModalShow} />
        </div>
      </div>
    );
  },
);

export default memo(FirstRationalStep);

import { useOutsideClick } from "@utils/hooks/useClickOutside";
import { constRoute } from "@utils/route";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./style.module.scss";

interface ProjectCreateNavType {
  labels: string[];
  selected: string;
  subtabs?: { [key: string]: string[] };
  subtabsCondition?: boolean;
}

const ProjectCreateNav = observer(
  ({
    labels,
    selected,
    subtabs,
    subtabsCondition = false,
  }: ProjectCreateNavType) => {
    const nav = useNavigate();
    const location = useLocation();
    const step = +location.pathname.split("/").pop();
    const isDraftPage = location.pathname.includes("draft");
    const isEvaluatePage = location.pathname.includes("evaluate");
    const isAdvisorPage = location.pathname.includes("/advisor/");
    const isRationalPage = location.pathname.includes("/rational-advisor/");
    const isTheoryOfChangePage =
      location?.pathname.includes("/theory-of-change/");
    const isNoObjectionProcedurePage = location?.pathname.includes(
      "/no-objection-procedure/",
    );
    const [dropdown, setDropdown] = useState(null);
    const ref = useOutsideClick(() => setDropdown(null));

    const timeOutRef = useRef(null);

    const handleChangeDraftStep = (stepToChange: number) => {
      nav(constRoute.draftProject + "/form/" + stepToChange);
    };

    const handleChangeEvaluateStep = (stepToChange: number) => {
      nav(constRoute.gradeProject + "/form/" + stepToChange);
    };

    const handleChangeAdvisorStep = (stepToChange: number) => {
      const step = stepToChange === 0 ? stepToChange + 1 : stepToChange;
      nav(constRoute.advisorProject + "/form/" + step);
    };

    const handleChangeTheoryOfChangeStep = (stepToChange: number) => {
      const step = stepToChange === 0 ? stepToChange + 1 : stepToChange;
      nav(constRoute.theoryOfChangeProject + "/form/" + step);
    };

    const handleChangeNoObjectionProcedureStep = (stepToChange: number) => {
      const step = stepToChange === 0 ? stepToChange + 1 : stepToChange;
      nav(constRoute.noObjectionProcedureProject + "/form/" + step);
    };

    const handleChangeRationalAdvisorStep = (stepToChange: number) => {
      const step = stepToChange === 0 ? stepToChange + 1 : stepToChange;
      nav(constRoute.rationalAdvisorProject + "/form/" + step);
    };

    return (
      <div style={{ position: "relative" }}>
        <div className={style.projectsPagesNav}>
          {labels.map((el, i) => {
            if (!subtabsCondition) {
              return (
                <div
                  key={el}
                  className={classNames(
                    style.navigationItem,
                    isRationalPage && style.navigationItemRationalPage,
                    isTheoryOfChangePage && style.navigationItemTheoryOfChange,
                  )}
                >
                  <div
                    className={`${style.text} ${
                      selected === el ||
                      (++i === +step && (isEvaluatePage || isRationalPage))
                        ? style.selectedText
                        : ""
                    } navigation-element-js`}
                    onClick={() =>
                      isNoObjectionProcedurePage
                        ? handleChangeNoObjectionProcedureStep(i)
                        : isEvaluatePage
                          ? handleChangeEvaluateStep(i)
                          : isAdvisorPage
                            ? handleChangeAdvisorStep(i)
                            : isTheoryOfChangePage
                              ? handleChangeTheoryOfChangeStep(i)
                              : isRationalPage
                                ? handleChangeRationalAdvisorStep(i)
                                : handleChangeDraftStep(i)
                    }
                  >
                    {el}
                  </div>
                </div>
              );
            }

            const keys = Object.keys(subtabs);
            const index = keys?.findIndex((key) => key === el);
            const relevantKeys = keys?.slice(0, index);

            const sum = relevantKeys?.reduce(
              (acc, key) => acc + subtabs?.[key]?.length + 1,
              1,
            );

            return (
              <div
                key={el}
                className={classNames(
                  style.navigationItem,
                  isEvaluatePage && style.navigationItemEvaluatePage,
                  isDraftPage && style.navigationItemEvaluatePage,
                  isRationalPage && style.navigationItemRationalPage,
                )}
              >
                <div
                  // ref={ref}
                  className={`${style.text} ${
                    selected === el ||
                    (subtabs[el].length + sum >= +step &&
                      sum <= +step &&
                      (isEvaluatePage || isRationalPage || isDraftPage))
                      ? style.selectedText
                      : ""
                  } navigation-element-js`}
                  onMouseEnter={() => {
                    clearTimeout(timeOutRef.current);
                    if (subtabs[el].length !== 0) {
                      subtabsCondition && (isEvaluatePage || isDraftPage)
                        ? setDropdown(++i)
                        : setDropdown(i + 1);
                    }
                  }}
                  onMouseLeave={() => {
                    timeOutRef.current = setTimeout(() => {
                      setDropdown(null);
                    }, 400);
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    isEvaluatePage
                      ? handleChangeEvaluateStep(sum)
                      : isAdvisorPage
                        ? handleChangeAdvisorStep(i)
                        : isTheoryOfChangePage
                          ? handleChangeTheoryOfChangeStep(i)
                          : isRationalPage
                            ? handleChangeRationalAdvisorStep(sum)
                            : handleChangeDraftStep(sum);
                  }}
                >
                  {el}
                </div>
              </div>
            );
          })}
        </div>

        {subtabsCondition &&
          dropdown &&
          subtabs[labels[dropdown - 1]].length > 0 && (
            <div
              ref={ref}
              className={style.dropdown}
              onMouseEnter={() => {
                clearTimeout(timeOutRef.current);
              }}
              onMouseLeave={() => {
                setDropdown(null);
              }}
              style={{
                left:
                  dropdown !== labels.length
                    ? `${(window.innerWidth / (isEvaluatePage || isDraftPage ? 6 : 4)) * (dropdown - 1) + 15}px`
                    : "unset",
                right: dropdown === labels.length ? "10px" : "",
              }}
            >
              {subtabs[labels[dropdown - 1]].map((x, i) => {
                const index =
                  +Object.values(subtabs).reduce((acc, val, index) => {
                    if (index < dropdown - 1) {
                      acc += val.length + 1;
                    }
                    return acc;
                  }, 1) +
                  i +
                  1;

                return (
                  <div
                    key={x}
                    style={{ cursor: "pointer" }}
                    className={`${style.text} ${
                      index === step ? style.selectedText : ""
                    } navigation-element-js`}
                    onClick={() =>
                      isNoObjectionProcedurePage
                        ? handleChangeNoObjectionProcedureStep(i)
                        : isEvaluatePage
                          ? handleChangeEvaluateStep(index)
                          : isAdvisorPage
                            ? handleChangeAdvisorStep(index)
                            : isTheoryOfChangePage
                              ? handleChangeTheoryOfChangeStep(index)
                              : isRationalPage
                                ? handleChangeRationalAdvisorStep(index)
                                : handleChangeDraftStep(index)
                    }
                  >
                    {x}
                  </div>
                );
              })}
            </div>
          )}
      </div>
    );
  },
);

export default ProjectCreateNav;

import React, { createContext, useContext, useEffect, useState } from "react";
import { Layout } from "antd";
import style from "./superadmin-layout.module.scss";
import { Content } from "antd/es/layout/layout";
import AdminHeader from "@components/layout/admin-layout/header/AdminHeader";
import { InviteModalProvider } from "@components/ModalContext/InviteModalContext";
import SuperAdminRouting from "../../../superadmin-roter-service";
import SideBar from "@components/layout/superadmin-layout/sideBar/SideBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { onMasterLogOutClearAll } from "@utils/common-functions";

const UserContext = createContext(null);
const CartDataContext = createContext(null);

const SuperAdminLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const [userDetails, setUserDetails] = useState(null);
  const [cardData, setCardData] = useState({});

  const {
    user: {
      getUserDetails,
      loadMasterAdminInfo,
      receiveAllUsers,
      handleGetDashboardData,
    },
  } = useStore(null);

  useEffect(() => {
    (async () => {
      const user = await loadMasterAdminInfo();

      if (user?.status === 401) {
        onMasterLogOutClearAll(constRoute.masteradminLogin);
      }
    })();
  }, []);
  const updateUserDetails = async () => {
    if (email) {
      const userData = await getUserDetails({ email });
      setUserDetails(userData);
    }
  };

  useEffect(() => {
    updateUserDetails();
  }, [email]);

  useEffect(() => {
    (async () => {
      await receiveAllUsers();

      const dashboardData = await handleGetDashboardData();

      setCardData(dashboardData);
    })();
  }, []);

  return (
    <UserContext.Provider value={{ userDetails, updateUserDetails }}>
      <CartDataContext.Provider value={cardData}>
        <Layout className={style.layout}>
          <InviteModalProvider>
            <div className={style.layoutHeader}>
              <AdminHeader />
            </div>

            <div className={style.layoutSidebar}>
              <SideBar />
            </div>

            <Content>
              <SuperAdminRouting />
            </Content>
          </InviteModalProvider>
        </Layout>
      </CartDataContext.Provider>
    </UserContext.Provider>
  );
};

export function useUserContext() {
  return useContext(UserContext);
}

export function useCartDataContext() {
  return useContext(CartDataContext);
}

export default SuperAdminLayout;

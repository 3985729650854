import DashboardIcon from "@assets/componentIcons/DashboardIcon";
import ResourceCenterIcon from "@assets/componentIcons/ResourceCenterIcon";
import UserAdminIcon from "@assets/componentIcons/UserAdminIcon";
import { constRoute } from "@utils/route";

export const navigationItems = [
  {
    name: "Monitoring",
    icon: DashboardIcon,
    navigation: constRoute.masteradminDashboard,
  },
  {
    name: "Manage Users",
    icon: UserAdminIcon,
    navigation: constRoute.masteradminUsers,
  },
  {
    name: "Prompts Panel",
    icon: ResourceCenterIcon,
    navigation: constRoute.masteradminPromptPanel,
  },
  {
    name: "Resource Center",
    icon: ResourceCenterIcon,
    navigation: constRoute.masteradminResource,
  },
];

export const mockUserData = [
  {
    id: 1,
    name: "John Doe",
    email: "johndoe@gmail.com",
    entityName: "User",
    country: "Brazil",
    date: "01/02/2023",
  },
  {
    id: 2,
    name: "Chris Ramirez",
    email: "chrisramirez@gmail.com",
    entityName: "User",
    country: "Puerto Rico",
    date: "01/05/2023",
  },
];

export enum ChatRole {
  USER = "You:",
  CHAT = "Climate Finance Copilot Results:",
}

export interface IChat {
  id: number;
  role: ChatRole;
  message: string;
  image?: File;
  files?: string[];
  isError?: boolean;
  image_url?: string;
  url?: string;
  containsFile?: boolean;
  isLastChatMessage: boolean;
}

import { observer } from "mobx-react";
import style from "./style.module.scss";
import styled from "styled-components";
import useWindowSize from "@utils/hooks/useWindowSize";
import { useEffect, useState } from "react";
import { useStore } from "@stores/root-store";

interface IUpgradePlanBar {
  onClose: () => void;
}

const UpgradePlanBar = observer(({ onClose }: IUpgradePlanBar) => {
  const screenWidth = useWindowSize().width;

  const [shouldForceUpdate, setShouldForceUpdate] = useState(false);

  const {
    user: { getUserType },
  } = useStore(null);

  const userType = getUserType;
  const free_trial_left: string = localStorage.getItem("free_trial_left");

  useEffect(() => {
    setShouldForceUpdate(!shouldForceUpdate);
  }, [userType]);

  const bannerTitle = (() => {
    switch (userType) {
      case "basic":
        return "Welcome to Basic!";
      case "premium":
        return "Welcome to Premium!";
      case "enterprise":
        return "Welcome to Enterprise!";
      default:
        return "Welcome";
    }
  })();

  const bannerDesc = (() => {
    switch (userType) {
      case "basic":
        return "You now have access to an array of powerful features, including our advanced single-function assistants and a highly versatile language model.";
      case "premium":
        return "You now have access to an impressive suite of features, including our single-function assistants, a powerful playground, and cutting-edge language models.";
      case "enterprise":
        return "You now have access to our complete, high-performance toolkit, featuring all of our advanced assistants and language models crafted to elevate your team's productivity.";
      default:
        return "Welcome";
    }
  })();

  return (
    <div className={style.upgradePlanBarContainer}>
      <Heading size={screenWidth < 537 && 10} weight={screenWidth < 430 && 400}>
        <h2>{bannerTitle}</h2>
        <div>
          <span>
            {bannerDesc}
            {userType === "premium" && (
              <span style={{ color: "#00b840" }}>
                {" "}
                {/*{free_trial_left} days left in trial*/}
              </span>
            )}
          </span>
        </div>
      </Heading>
      <img
        className={style.closeImg}
        src="/imgs/closeIcon.svg"
        alt="close"
        onClick={onClose}
        width={12}
        height={12}
      />
    </div>
  );
});

export default UpgradePlanBar;

const Heading = styled.p<{ size?: any; weight?: any }>`
  font-family: Inter;
  font-style: normal;
  font-weight: ${(p) => (p.weight ? p.weight : 500)};
  font-size: ${(p) => (p.size ? p.size : 14)}px;
  line-height: 24px;
  text-align: left;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  padding-left: 16px;
  border-left: 3px solid #00b840;
`;

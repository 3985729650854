import StopIcon from "@assets/icons/StopIcon";
import Copied from "@assets/icons/copied.svg";
import Close from "@assets/icons/cross.svg";
import Copy from "@assets/icons/copy.svg";
import UploadIcon from "@assets/icons/upload.svg";
import { CommonInput } from "@commonComponents/input";
import { useStore } from "@stores/root-store";
import { validateMessages } from "@utils/json-data";
import { Form, Upload } from "antd";
import classNames from "classnames";
import React, { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import style from "./style.module.scss";
import SpinnerGeneration from "../spinner-generating";
import ReportModal from "@components/modal/report-modal";
import removeMd from "remove-markdown";
import { resizeImageAsync } from "@utils/common-functions";

interface GradeFormProps {
  Text: JSX.Element;
  disabled: boolean;
  maxLength: number;
  handleSubmit: (saveOnly: boolean) => void;
  form: any;
  initialState: any;
  placeholder?: string;
  isSubmitting?: boolean;
  value?: string;
  section?: string;
  isValid?: boolean;
  isFormPage?: boolean;
  setIsValid: (data: boolean) => void;
  step: string;
  handleStopGenerate: () => void;
  handleViewReport: () => void;
  sectionOutputPresent: boolean;
  isLoading: boolean;
  base64Images?: string[] | null;
  setBase64Images?: React.Dispatch<React.SetStateAction<string[] | null>>;
  allowUpload: boolean | undefined;
  isAnswer?: boolean;
  selectedFiles: any[] | null;
  setSelectedFiles: React.Dispatch<React.SetStateAction<any[] | null>>;
}

const GradeForm: React.FC<GradeFormProps> = ({
  Text,
  disabled,
  maxLength,
  handleSubmit,
  form,
  initialState,
  placeholder,
  value,
  isSubmitting,
  section,
  isValid,
  isFormPage,
  setIsValid,
  step,
  handleStopGenerate,
  handleViewReport,
  sectionOutputPresent,
  isLoading,
  base64Images,
  setBase64Images,
  allowUpload,
  isAnswer,
  selectedFiles,
  setSelectedFiles,
}) => {
  const { pathname } = useLocation();
  const type = pathname.split("/")[3];
  const [copied, setCopied] = useState(false);

  const [count, setCount] = useState({
    characters: +initialState?.input?.length || 0,
    words: +initialState?.input?.trim().split(" ").length || 0,
  });

  const wordLimits = [1000, 1500, 1500, 300, 500, 500];

  const {
    user: { handleCheckInputTokensValid },
  } = useStore(null);

  const checkLengthOfInput = async (value: string) => {
    const data = {
      functionality: "concept note evaluator",
      section: section,
      input: value,
    };

    setCount({
      characters: +value.length,
      words: value
        .trim()
        .split(" ")
        .filter((el) => !!el).length,
    });

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    if (!copied) return;

    setTimeout(() => {
      setCopied(null);
    }, 2000);
  }, [copied]);

  useEffect(() => {
    async function resizeAndSetImage() {
      if (selectedFiles?.length > 0) {
        try {
          const base64Images = [];

          for (const e of selectedFiles) {
            const img = await resizeImageAsync(e.originFileObj, 1024, 768);
            base64Images.push(img);
          }

          setBase64Images(base64Images); // Store the base64 image in state
        } catch (error) {
          console.error("Error resizing image:", error);
        }
      } else {
        setBase64Images([]);
      }
    }

    resizeAndSetImage();
  }, [selectedFiles]); // The effect depends on `selectedFiles`

  let isRequired = false;
  if (+step !== 12 && !disabled) {
    isRequired = true;
  }

  return (
    <>
      <div className={style.formWrapper}>
        <Form
          className={style.form}
          form={form}
          autoComplete="false"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          layout="vertical"
          initialValues={{
            q: initialState?.input,
          }}
        >
          <div className={style.labelContainer}>
            <span className={style.label}>{Text}</span>
            {type === "results" ? null : (
              <span
                className={
                  isLoading === false && sectionOutputPresent
                    ? style.viewOutputButton
                    : style.viewOutputButtonDisabled
                }
                onClick={() =>
                  sectionOutputPresent ? handleViewReport() : null
                }
              >
                View Last Output
              </span>
            )}
          </div>
          <div className={style.textareaWrapper}>
            <Form.Item>
              {allowUpload && isFormPage && base64Images && (
                <div className={style.previewWrapper}>
                  {base64Images.map((img, index) => (
                    <div key={index}>
                      <img src={img} alt="preview" />
                      <div
                        className={style.clearFile}
                        onClick={() => {
                          setBase64Images((prevImages: string[]) =>
                            prevImages.filter((_, i) => i !== index),
                          );
                          setSelectedFiles((prevFiles) =>
                            prevFiles.filter((_, i) => i !== index),
                          );
                        }}
                      >
                        <img src={Close} alt="Clear file" />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <Form.Item
                name={"q"}
                rules={[
                  { required: isRequired, message: "This field is required" },
                ]}
                noStyle
              >
                {isSubmitting && !isAnswer ? (
                  <SpinnerGeneration />
                ) : (
                  <CommonInput
                    placeholder={
                      isSubmitting
                        ? "If you don’t see any output below within 7 seconds, you may have exceeded the permitted word limit in your input. Please reduce your input - thank you!"
                        : placeholder || ""
                    }
                    value={isFormPage ? initialState?.input : "aa"}
                    defaultValue={!isFormPage && !isSubmitting ? value : ""}
                    disabled={disabled}
                    size={3}
                    // TODO: removed input limit until further notice
                    // maxlength={maxLength * 10}
                    // onChange={(e) => {
                    //   checkLengthOfInput(e.target.value);
                    // }}
                    inputType="textarea"
                    style={{
                      cursor: isLoading ? "wait" : "",
                      minHeight: "55vh",
                    }}
                    autoSizeCheck={{
                      minRows: isFormPage ? 19 : 2,
                      maxRows: 1111,
                    }}
                    className={classNames(style.textarea)}
                    isMarkdown={!isFormPage}
                  />
                )}
              </Form.Item>
            </Form.Item>
            <div>
              {!isFormPage &&
                form.getFieldValue("q") &&
                (copied ? (
                  <img src={Copied} alt="copy" />
                ) : (
                  <img
                    src={Copy}
                    alt="copy"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        removeMd(form.getFieldValue("q")),
                      );
                      setCopied(true);
                    }}
                  />
                ))}
            </div>
          </div>
        </Form>
        {/*<div>Words: {count.words} / 1000</div>*/}
        {/*<div>Characters: {count.characters} / 1000</div>*/}
        {allowUpload && isFormPage && (
          <div className={style.uploadButtonBlock}>
            <div className={style.uploadButtonWrapper}>
              <Upload
                accept="image/*"
                onChange={(info) => {
                  setSelectedFiles(info.fileList);
                }}
                multiple
                fileList={[]}
                itemRender={() => null}
              >
                <div className={style.uploadButtonBlockButton}>
                  <img alt="upload" src={UploadIcon} />
                  <span>Upload Images</span>
                </div>
              </Upload>
            </div>
          </div>
        )}
        {/*{!isValid && (*/}
        {/*  <div style={{ color: "red", fontSize: "14px", marginTop: "19px" }}>*/}
        {/*    You have exceeded the maximum length for this field*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{pathname.includes("/form/") && (*/}
        {/*  <div style={{ paddingTop: "5px", marginTop: "19px" }}>*/}
        {/*    The max word limit for this page is {maxLength} words*/}
        {/*  </div>*/}
        {/*)}*/}

        {!isFormPage && (
          <div
            style={{
              border: "1px solid #E0E0E0",
              position: "relative",
              top: "-16px",
              margin: "1px",
              marginTop: "15px",
              padding: "10px 17px",
              display: "flex",
              justifyContent: "end",
              borderRadius: "0 0 5px 5px",
            }}
          >
            <div
              style={{
                height: "fit-content",
                cursor: !isSubmitting ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (isSubmitting) {
                  handleStopGenerate();
                }
              }}
            >
              <StopIcon color={!isSubmitting ? "#dddddd" : ""} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(GradeForm);

import { Button, Checkbox, Form, Input, Select, Spin } from "antd";
import { observer } from "mobx-react";
import { memo, useEffect, useState } from "react";
import style from "./style.module.scss";
import Eye from "@assets/icons/Eye.png";
import EyeOff from "@assets/icons/EyeOff.png";
import welcomeLogo from "@assets/images/Janus-logo-black.png";
import { validateMessages } from "@utils/json-data";
import { useStore } from "@stores/root-store";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { notification } from "@utils/notifications";
import { allCountries } from "@utils/helpers/allCountries";

const SignUp = observer(() => {
  const [signUpForm] = Form.useForm();
  const navigate = useNavigate();
  const [isOtherType, setisOtherType] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [passwordValue, setPassWordValue] = useState("");
  const {
    user: { isLoadingEmailVerification, onSendEmailVerification },
  } = useStore(null);

  useEffect(() => {
    signUpForm.setFieldValue("orgtype", "NGO");
  }, []);

  const onFormSubmit = async (values) => {
    if (passwordValue?.length < 8 && passwordValue) {
      notification.error("Password must be 8 characters");
      return;
    } else {
      const payload = {
        name: values?.name,
        // orgtype: values?.orgtype,
        // orgname: values?.orgname,
        // role: values?.role,
        country: values?.country,
        entity: values?.entity,
        email: values?.email.toLocaleLowerCase(),
        phone: values?.phone,
        password: passwordValue,
        plan: "Premium",
      };
      if (isOtherType) {
        payload["orgtype"] = values?.orgTypeName;
      }
      if (passwordValue === values.confirmPassword) {
        localStorage.setItem("signupPayload", JSON.stringify(payload));
        const res = await onSendEmailVerification({
          email: values?.email?.toLocaleLowerCase(),
          name: values?.name?.toLocaleLowerCase(),
        });
        res?.verification_code && navigate(constRoute?.verifyEmail);
      } else {
        notification.error("Confirm Password is not Match with Password");
      }
    }
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setIsAccept(e.target.checked);
  };

  return (
    <div className={style.mainSignUpWrraper}>
      <div style={{ width: 500, margin: 20 }}>
        <div className={style.welcomeWrraper}>
          <img src={welcomeLogo} alt="" />
          <h2>Create Your Account</h2>
          <p style={{ width: "590px" }}>
            Create an account and enjoy free access to our Premium tier for 7
            days. After the trial ends, your account will switch to the Basic
            tier unless you choose to subscribe to keep Premium.
          </p>
        </div>
        <Form
          form={signUpForm}
          name={"basic"}
          onFinish={onFormSubmit}
          autoComplete={"off"}
          validateMessages={validateMessages}
          layout="vertical"
          className={style.signUpForm}
        >
          <Form.Item
            label={"Full Name"}
            name="name"
            rules={[
              {
                required: true,
                message: "Full name is required",
              },
            ]}
          >
            <Input placeholder="Enter full name" />
          </Form.Item>
          {/*<Form.Item*/}
          {/*  label={"Last Name"}*/}
          {/*  name={"lastname"}*/}
          {/*  rules={[*/}
          {/*    {*/}
          {/*      required: true,*/}
          {/*      message: "Last name is required",*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*>*/}
          {/*  <Input placeholder="Enter last name" />*/}
          {/*</Form.Item>*/}
          {/* <Form.Item label={"Organization Type"} name={"orgtype"}>
            <Select
              defaultValue="NGO"
              onChange={(e) => {
                if (e === "Other") {
                  setisOtherType(true);
                } else {
                  setisOtherType(false);
                }
              }}
              options={[
                { value: "NGO", label: "NGO" },
                { value: "Government", label: "Government" },
                { value: "Foundation", label: "Foundation" },
                { value: "Bank", label: "Bank" },
                { value: "Other", label: "Other" },
              ]}
            />
          </Form.Item>
          {isOtherType && (
            <Form.Item label={"Add Organization Type"} name={"orgTypeName"}>
              <Input placeholder="Enter your organization type" />
            </Form.Item>
          )}
          <Form.Item label={"Organization Name"} name={"orgname"}>
            <Input placeholder="Enter your organization name" />
          </Form.Item>
          <Form.Item label={"Role"} name={"role"}>
            <Input placeholder="Enter your role" />
          </Form.Item>
          <Form.Item label={"Country"} name={"country"}>
            <Input placeholder="Enter your country" />
          </Form.Item> */}
          <Form.Item
            label={"Email Address"}
            name={"email"}
            rules={[
              {
                required: true,
                type: "email",
                message: `Please provide a valid email address`,
              },
            ]}
          >
            <Input placeholder="Enter email address" />
          </Form.Item>
          {/*<Form.Item*/}
          {/*  label={"Phone Number"}*/}
          {/*  name={"phone"}*/}
          {/*  // rules={[*/}
          {/*  //      {*/}
          {/*  //          required: true,*/}
          {/*  //          message: `Phone number is required`,*/}
          {/*  //      },*/}
          {/*  //  ]}*/}
          {/*>*/}
          {/*  <Input placeholder="Enter phone number (optional)" />*/}
          {/*</Form.Item>*/}
          <Form.Item
            label={"Country"}
            name={"country"}
            rules={[
              {
                required: true,
                type: "string",
                message: `Please select the country`,
              },
            ]}
          >
            <Select
              defaultValue="Select country"
              placeholder="Select country"
              style={{ width: "100%" }}
              options={allCountries}
            />
          </Form.Item>

          <Form.Item
            label={"Entity Type"}
            name={"entity"}
            rules={[
              {
                required: true,
                type: "string",
                message: `Please select the entity type`,
              },
            ]}
          >
            <Select
              defaultValue="Select entity type"
              placeholder="Select entity type"
              style={{ width: "100%" }}
              options={[
                { value: "Project Owner", label: "Project Owner" },
                {
                  value: "National Designated Authority (NDA)",
                  label: "National Designated Authority (NDA)",
                },
                {
                  value: "Executing Entity (EE)",
                  label: "Executing Entity (EE)",
                },
                {
                  value: "Accredited Entity (AE)",
                  label: "Accredited Entity (AE)",
                },
                { value: "Other", label: "Other" },
              ]}
            />
          </Form.Item>

          <Form.Item
            label={"Password"}
            name={"password"}
            //  rules={[
            //   {
            //     required: true,
            //     message: "Password is required",
            //   },
            // ]}
          >
            <Input.Password
              placeholder="Enter password"
              onChange={(e) => {
                setPassWordValue(e?.target?.value);
              }}
              iconRender={(visible) =>
                visible ? (
                  <img height={18} width={18} src={Eye} alt="" />
                ) : (
                  <img height={18} width={18} src={EyeOff} alt="" />
                )
              }
            />

            <span className={style.passwordSuggestionMsg}>
              {" "}
              Password must be 8 characters.
            </span>
          </Form.Item>

          <Form.Item label={"Confirm Password"} name={"confirmPassword"}>
            <Input.Password
              placeholder="Confirm password"
              iconRender={(visible) =>
                visible ? (
                  <img height={18} width={18} src={Eye} alt="" />
                ) : (
                  <img height={18} width={18} src={EyeOff} alt="" />
                )
              }
            />
          </Form.Item>
        </Form>
        <div>
          <div className={style.signUpWrraper}>
            <div className={style.checkBox}>
              <Checkbox
                // defaultChecked={true}
                onChange={onChange}
              />
              <p style={{ fontSize: "20px" }}>
                I accept the{" "}
                <a
                  href={constRoute.TermOfUse}
                  target="_blank"
                  rel="noreferrer"
                  className={style.termsStyle}
                >
                  Terms
                </a>{" "}
                &{" "}
                <a
                  target="_blank"
                  href={constRoute.privacyPolicy}
                  rel="noreferrer"
                  className={style.termsStyle}
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <Form form={signUpForm} onFinish={onFormSubmit}>
              <Button
                htmlType="submit"
                disabled={!isAccept}
                className={style.signUpBtn}
              >
                {(isLoadingEmailVerification && <Spin />) || "Sign Up"}
              </Button>
            </Form>
          </div>
          <div className={style.loginWrraper}>
            <p>Already have an account?</p>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate(constRoute?.login)}
            >
              Log In
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default memo(SignUp);

import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { Modal } from "antd";
import style from "./style.module.scss";
import welcomeCharsImage from "@assets/images/welcome_modal_chars.png";

// import { userApi } from "@api";

interface modalProps {
  open?: any;
  setOpenModal?: any;
}

const SignupTrialModal: React.FC<modalProps> = ({ open, setOpenModal }) => {
  const navigate = useNavigate();
  const handleClose = async () => {
    localStorage.removeItem("showInfoModal");
    setOpenModal(false);
  };
  const handleTools = async () => {
    localStorage.removeItem("showInfoModal");
    navigate(constRoute.selectDonor);
  };
  const handlePlayground = async () => {
    localStorage.removeItem("showInfoModal");
    navigate("/playground");
  };

  return (
    <Modal
      width={733}
      title=""
      footer={null}
      open={open}
      centered
      onCancel={handleClose}
      styles={{
        content: {
          padding: 0,
        },
      }}
    >
      <div className={style.top}>
        <img src={welcomeCharsImage} style={{ width: "241px" }} />
      </div>
      <div className={style.contentWrapper}>
        <article>
          <p className={style.title}>
            Welcome to the world's first AI solution
            <br />
            specifically designed for climate finance!
          </p>
          <p>
            This powerful platform helps you secure funding for your climate
            projects.
            <br />
            Here's how it works:
          </p>
          <ul>
            <li>
              Playground: A user interface powered by the LLM of your choice and
              customized for climate focused professionals who need more
              workspace and features compared to other AI solutions.
            </li>
            <li>
              Single function assistants: Specialized tools, aligned with GCF
              criteria, that take the headache out of critical tasks like
              developing a Theory of Change and Climate Rationale.
            </li>
          </ul>
          <p>
            <b>Ready to get started?</b>
            <br />
            Start your adventure in our Playground space first. It’s the perfect
            place to brainstorm, develop project ideas, research and much more.
            When you're ready to take things further, our single function
            assistants will get you to the finance finish line with ease.
          </p>
          <p className={style.contentWrapperSignature}>
            <b>Let’s make an impact together!</b> <br />- The Janus Team
          </p>
        </article>
      </div>
      <div className={style.footer}>
        <div className={style.footerContent}>
          <button onClick={handlePlayground} className={style.gotItBtn}>
            Go to Playground
          </button>
          <button
            onClick={handleTools}
            className={[style.gotItBtn, style.gotItBtnShallow].join(" ")}
          >
            Go to Single Function Tools
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(SignupTrialModal);
